import SelectMoreItemsHeader from 'app/components/selectMoreItems/SelectMoreItemsHeader';
import React, { FC } from 'react';
import SelectSocialProfileOption, {
  SocialProfile,
} from './SelectSocialProfileOption';

type Props = {
  profiles: SocialProfile[];
  onSelect: (id: number | string) => void;
  selectedIds: (number | string)[];
  additionalHeader?: string;
  onSelectAll?: (e: React.MouseEvent) => void;
  onSelectNone?: (e: React.MouseEvent) => void;
};

const SelectSocialProfiles: FC<Props> = (props) => {
  return (
    <div className="tw-flex tw-flex-col tw-gap-6">
      <SelectMoreItemsHeader
        totalCount={props.profiles.length}
        selectedCount={props.selectedIds.length}
        onSelectAll={props.onSelectAll}
        onSelectNone={props.onSelectNone}
        className="tw-mt-4"
      />
      <div className="tw-flex tw-flex-col">
        {props.additionalHeader && (
          <div className="tw-mb-6 tw-rounded-md tw-bg-grayscale-5 tw-px-4 tw-py-[10px]">
            <span className="tw-text-md tw-font-medium tw-text-grayscale-180">
              {props.additionalHeader}
            </span>
          </div>
        )}
        <div className=" tw-flex tw-flex-col tw-gap-6">
          {props.profiles.map((profile) => {
            const isSelected = props.selectedIds.includes(profile.id);
            return (
              <SelectSocialProfileOption
                key={profile.id}
                profile={profile}
                isSelected={isSelected}
                onClick={() => {
                  props.onSelect(profile.id);
                }}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SelectSocialProfiles;
