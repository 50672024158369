import React, { FC, useEffect, useRef, useState } from 'react';
import AccordionWidgetIcon from 'app/modules/posts/components/accordionWidget/AccordionWidgetIcon';
import AccordionWidget from 'app/modules/posts/components/AccordionWidget';
import AccordionWidgetTitle from 'app/modules/posts/components/accordionWidget/AccordionWidgetTitle';
import { PostDetail } from 'types/PostDetail';
import LabelTag from 'components/shared/LabelTag';
import { Tag } from '@kontentino/ui';
import PageTagsSelect from 'components/shared/PageTagsSelect';
import { PostLabel } from 'types/Post';
import { useMutation } from 'react-query';
import PostApi from 'api/post';
import LabelsView from 'components/shared/detail/LabelsView';
import { UserPermission } from 'constants/userPermission';
import UserPermissionGate from 'components/UserPermissionGate';
import { queryClient } from 'api/client';
import { queryKey } from 'constants/queryKey';
import { STATUS } from '@kontentino/kontentino-constants/Posts';

type Props = {
  isLoading: boolean;
  isError: boolean;
  accordionProps?: Partial<React.ComponentProps<typeof AccordionWidget>>;
  post: {
    id?: number;
    page?: PostDetail['page'];
    tags: PostDetail['tags'];
    status?: PostDetail['status'];
  };
};

const Labels: FC<Props> = ({ isLoading, isError, accordionProps, post }) => {
  const [tags, setTags] = useState<PostLabel[]>(post.tags);
  const initialTagIdsRef = useRef(post.tags.map((tag) => tag.id));

  const visibleTags = post.tags.slice(0, 2);
  const truncatedTagsCount = post.tags.length - visibleTags.length;

  useEffect(() => {
    setTags(post.tags);
    initialTagIdsRef.current = post.tags.map((tag) => tag.id);
  }, [post.tags]);

  const savePostPartial = useMutation(PostApi.savePartial, {
    onSuccess(data) {
      queryClient.invalidateQueries(queryKey.postDetail(data.id));
      queryClient.invalidateQueries(queryKey.postsMeta());
      queryClient.invalidateQueries(queryKey.posts());
    },
  });

  const handleTagsOnBlur = (postId: number) => {
    const currentTagIds = tags.map((tag) => tag.id);
    const tagsChanged =
      initialTagIdsRef.current.length !== currentTagIds.length ||
      initialTagIdsRef.current.some((id) => !currentTagIds.includes(id));

    if (tagsChanged) {
      savePostPartial.mutate({
        postId,
        values: { tags },
      });
      initialTagIdsRef.current = currentTagIds;
    }
  };

  const isPostNotEditable =
    post.status === STATUS.SCHEDULED ||
    post.status === STATUS.POSTED ||
    post.status === STATUS.EXPORTED;

  return (
    <AccordionWidget
      data-cy="post-preview-labels"
      icon={<AccordionWidgetIcon variant="labels" />}
      title={<AccordionWidgetTitle variant="labels" />}
      titleSuffix={(isOpen) =>
        visibleTags.length > 0 &&
        !isOpen && (
          <div className="tw-flex tw-items-center tw-justify-end tw-gap-1">
            {visibleTags.map((tag) => (
              <LabelTag
                key={tag.id}
                size="small"
                className="tw-max-w-[100px]"
                name={tag.name}
                color={tag.color}
              />
            ))}
            {truncatedTagsCount > 0 && (
              <Tag size="small" className="tw-whitespace-nowrap">
                + {truncatedTagsCount}
              </Tag>
            )}
          </div>
        )
      }
      {...accordionProps}
    >
      {
        <UserPermissionGate
          scopes={UserPermission.POST_EDIT}
          RenderError={() => (
            <LabelsView labels={tags} isLoading={isLoading} isError={isError} />
          )}
        >
          <PageTagsSelect
            pageId={post.page?.id ?? null}
            value={tags}
            onChange={setTags}
            isDisabled={isLoading || isPostNotEditable}
            onBlur={post.id ? () => handleTagsOnBlur(post.id!) : undefined}
          />
        </UserPermissionGate>
      }
    </AccordionWidget>
  );
};

export default Labels;
