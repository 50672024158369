import { FC, ReactNode } from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import style from './DatePicker.module.scss';
import 'react-datepicker/dist/react-datepicker.css';
import { DropdownMenu, Icon, IconButton, Tooltip } from '@kontentino/ui';
import clsx from 'clsx';
import DatePickerPlaceholder from './DatepickerPlaceholder';
import { useUser } from 'modules/user/userSelector';
import { faX } from '@fortawesome/pro-regular-svg-icons/faX';

type Props = ReactDatePickerProps<boolean> & {
  isDisabled?: boolean;
  isSelected?: boolean;
  hideDefaultDatePicker?: boolean;
  label: string;
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  refreshKey?: string;
  placeholderClassname?: string;
  contentClassname?: string;
  dataCy?: {
    day?: string;
    placeholder?: string;
  };
  clearButtonTooltipContent?: string | ReactNode;
  onClear?: () => void;
};

const DatePicker: FC<Props> = ({
  isOpen,
  onOpenChange,
  isDisabled,
  isSelected,
  hideDefaultDatePicker,
  label,
  refreshKey,
  children,
  placeholderClassname,
  dataCy,
  contentClassname,
  clearButtonTooltipContent,
  onClear,
  ...props
}) => {
  const user = useUser();

  return (
    <DropdownMenu.Root open={isOpen} onOpenChange={onOpenChange}>
      {onClear && props.selected && (
        <div className="tw-absolute tw-right-10 tw-top-[6px] tw-z-10">
          <Tooltip content={clearButtonTooltipContent}>
            <IconButton
              icon={
                <Icon
                  icon={faX}
                  size={'sm'}
                  className="tw-text-grayscale-100"
                />
              }
              size="small"
              onClick={onClear}
              variant="ghost"
              data-cy="clear-button"
            />
          </Tooltip>
        </div>
      )}

      <DropdownMenu.Trigger disabled={isDisabled}>
        <div>
          <DatePickerPlaceholder
            label={label}
            isOpen={isOpen}
            isSelected={isSelected}
            isDisabled={isDisabled}
            className={clsx('tw-relative tw-w-full', placeholderClassname)}
            dataCy={dataCy?.placeholder}
          />
        </div>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className={clsx(
            style.datePicker,
            'tw-top-1 !tw-z-[999999] tw-gap-y-0 tw-p-0',
            contentClassname,
          )}
          data-cy="datepicker-content"
        >
          {!hideDefaultDatePicker && (
            <ReactDatePicker
              {...props}
              key={refreshKey}
              inline
              isClearable={true}
              customInput={<></>}
              renderDayContents={(day) => (
                <span data-cy={dataCy?.day} className="day">
                  {day}
                </span>
              )}
              calendarStartDay={user.useMeridianTime ? 1 : 0}
            />
          )}
          {children}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

export default DatePicker;
