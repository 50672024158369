import React, { FC } from 'react';
import { MODULE_STATUS } from 'constants/modules';
import {
  AuthorizationErrorResponse,
  SocialLoginDoneResponse,
} from 'types/SocialLogin';
import { ModuleStatus } from 'types/ModuleStatus';
import { NewAddedPage } from 'types/Page';
import Modal from 'components/shared/Modal';
import { ActivityIndicator, Alert } from '@kontentino/ui';
import { useTranslation } from 'react-i18next';
import GenericProfilesSelectForm from 'components/modals/newSocialMediaProfile/automaticForm/GenericProfilesSelectForm';
import { useQuery } from 'react-query';
import { queryKey } from 'constants/queryKey';
import SocialLoginApi from 'app/modules/socialLogin/api';
import { useToast } from 'app/hooks/useToast';
import ErrorStepsGuide from '../ErrorStepsGuide';

type Props = {
  status: ModuleStatus;
  pageType: number;
  pageId?: number;
  authorization?: SocialLoginDoneResponse;
  error?: AuthorizationErrorResponse;
  onPageAdded(addedPage: NewAddedPage): void;
  onGoBack(): void;
  isSingleSelect?: boolean;
  isLoading?: boolean;
};

const Form: FC<Props> = (props) => {
  const { t } = useTranslation();
  const connectedPagesExternalIds = useQuery(
    queryKey.socialLoginConnectedPagesExternalIds(),
    () => SocialLoginApi.connectedPagesExternalIds(props.pageType),
  );
  const toast = useToast();

  return (
    <div>
      <Modal.Content>
        <div className="tw-flex tw-min-w-[400px] tw-flex-col tw-items-center tw-justify-center">
          {(props.isLoading || connectedPagesExternalIds.isLoading) && (
            <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-py-6">
              <ActivityIndicator className="tw-h-12 tw-w-12" />
              <p className="tw-mt-6 tw-text-md">Please wait...</p>
            </div>
          )}

          {(props.status === MODULE_STATUS.Failed ||
            connectedPagesExternalIds.isError) && (
            <>
              <Alert
                variant="danger"
                cta={{
                  label: t('goBack'),
                  onClick: props.onGoBack,
                }}
                className="tw-my-5"
              >
                <p className="tw-font-semibold">
                  {t('unableToAuthorizeSocialLogin')}
                </p>
                {typeof props.error?.reason === 'string' && (
                  <div className="tw-mt-2">
                    <strong className="tw-font-semibold">{t('reason')}:</strong>
                    {` ${props.error.reason}`}
                  </div>
                )}
              </Alert>
              <div className="tw-mt-3 tw-pb-6">
                <ErrorStepsGuide pageType={props.pageType} />
              </div>
            </>
          )}
        </div>
        {props.authorization && connectedPagesExternalIds.data && (
          <GenericProfilesSelectForm
            connectedPagesExternalIds={connectedPagesExternalIds.data}
            page={{
              type: props.pageType,
              id: props.pageId,
            }}
            data={props.authorization.data}
            onPagesCreate={props.onPageAdded}
            onError={(e) => {
              toast(e?.userMessage ?? t('somethingWentWrong'), 'error');
            }}
          />
        )}
      </Modal.Content>
    </div>
  );
};

export default Form;
