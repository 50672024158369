import React from 'react';
import MobileNavigation from './navigation/MobileNavigation';
import useInAppNotificationsCountQuery from 'app/modules/inAppNotifications/hooks/useInAppNotificationsCountQuery';
import { useLogoutUser } from 'modules/app/appSelector';
import { useAccount } from 'modules/account/accountSelector';
import useNavigation from 'components/navigation/hooks/useNavigation';

const UserMobileNavigation = () => {
  const { user, items, activeMenuItem } = useNavigation();
  const { data: notificationsData } = useInAppNotificationsCountQuery();
  const logoutUser = useLogoutUser();
  const { isSubscription } = useAccount();

  return (
    <MobileNavigation
      menuItems={items}
      user={user}
      isSubscription={isSubscription}
      activeItem={activeMenuItem}
      notificationsCount={notificationsData?.count || 0}
      logoutUser={logoutUser}
    />
  );
};

export default UserMobileNavigation;
