import React, { FC } from 'react';
import PageMetricCardContent from './PageMetricCardContent';
import { MetricProperty } from 'app/modules/insights/types/MetricProperty';
import PageMetricCardContentGraph from './PageMetricCardCollapsedContent';
import { useInsightsContainer } from 'components/insights/context';
import { Page } from 'app/modules/insights/types/Page';
import { usePageCardQuery } from '../../hooks/usePageCardQuery';
import { ActivityIndicator } from '@kontentino/ui';
import { GraphData } from 'app/modules/insights/types/PagePerformance';
import { formatDate } from 'app/utils/date';
import PageMetricCardContentProvider from 'app/modules/insights/modules/pagePerformance/components/pageMetricCard/PageMetricCardContentProvider';
import PageMetricCardContentEmpty from 'app/modules/insights/modules/pagePerformance/components/pageMetricCard/PageMetricCardContentEmpty';

type Props = {
  page: Page;
  metric: MetricProperty;
  expanded: boolean;
  onToggleExpandClick: () => void;
  dataName?: {
    card?: string;
    expandButton?: string;
    summaryMetrics?: string;
    metricTable?: string;
    submetricsTabBar?: string;
  };
  chartWidth?: number | string;
  chartHeight?: number | string;
  showTabs?: boolean;
  defaultTab?: string;
  useMeridianTime: boolean;
};

const PageMetricCard: FC<Props> = ({
  page,
  metric,
  expanded,
  onToggleExpandClick,
  dataName,
  chartWidth,
  chartHeight,
  showTabs = true,
  defaultTab,
  useMeridianTime,
}) => {
  const { filterState } = useInsightsContainer();

  const { data, loading, isEmpty } = usePageCardQuery({
    metric: metric,
    page: page,
    date: {
      start: filterState.startDate,
      end: filterState.endDate,
    },
    comparedDate: {
      start: filterState.comparedStartDate,
      end: filterState.comparedEndDate,
    },
  });

  const isCompared = !!(
    filterState.comparedStartDate && filterState.comparedEndDate
  );

  const combinedMetricComparedData = ((): GraphData[] => {
    const firstMetric = data?.generalData?.[2];
    const secondMetric = data?.generalData?.[3];

    return [
      {
        date:
          formatDate(filterState.startDate) +
          ' - ' +
          formatDate(filterState.endDate),
        value: Number(firstMetric?.value),
        comparedValue: Number(secondMetric?.value),
      },
      {
        date:
          formatDate(filterState.comparedStartDate) +
          ' - ' +
          formatDate(filterState.comparedEndDate),
        value: Number(firstMetric?.compareValue),
        comparedValue: Number(secondMetric?.compareValue),
      },
    ];
  })();

  const renderContent = () => {
    if (loading)
      return (
        <div className="tw-flex tw-h-full tw-items-center tw-justify-center">
          <ActivityIndicator className="tw-h-7 tw-w-7" />
        </div>
      );

    if (isEmpty || !data) {
      return <PageMetricCardContentEmpty page={page} />;
    }

    return (
      <PageMetricCardContentProvider
        metricCardData={data}
        defaultTab={defaultTab}
        useMeridianTime={useMeridianTime}
      >
        <PageMetricCardContentGraph
          showTabs={showTabs}
          chartWidth={chartWidth}
          chartHeight={chartHeight}
          expanded={expanded}
          onToggleExpandClick={onToggleExpandClick}
          filterState={filterState}
          metricCardData={data}
          isCompared={isCompared}
          combinedMetricComparedData={combinedMetricComparedData}
          isCombinedMetric={data.isCombinedMetric}
          title={metric.title}
          dataName={{
            summaryMetrics: dataName?.summaryMetrics,
            metricTable: dataName?.metricTable,
          }}
        />
      </PageMetricCardContentProvider>
    );
  };

  return (
    <PageMetricCardContent
      metric={metric}
      onToggleExpandClick={onToggleExpandClick}
      dataName={dataName}
    >
      {renderContent()}
    </PageMetricCardContent>
  );
};

export default PageMetricCard;
