import { get, post } from 'api/client';
import config from 'config';
import { PostLabel } from 'types/Post';

const TagsApi = {
  getRecent(pageId: number, limit?: number): Promise<PostLabel[]> {
    return get(`page/${pageId}/recent-tags`, {
      baseURL: config.API_SERVICE_ENDPOINT,
      params: {
        limit,
      },
    });
  },
  getAll(pageId: number): Promise<PostLabel[]> {
    return get('tags/ajaxGetTags', {
      params: {
        pageId,
      },
    });
  },
  create(data: {
    pageId: number;
    name: string;
    color?: string;
  }): Promise<PostLabel> {
    const formData = new FormData();

    formData.append('page_id', data.pageId.toString());
    formData.append('name', data.name);

    if (data.color) {
      formData.append('color', data.color);
    }

    return post('tags/ajaxCreateNewTag', formData);
  },
};

export default TagsApi;
