import UserpilotUtils from 'app/utils/userpilot';
import { useModalHistory } from 'context/ModalRouter';
import { PageThunk } from 'modules/page/pageActions';
import { useDispatch } from 'react-redux';
import { useToast } from 'app/hooks/useToast';
import Logger from 'utils/logger';
import { NewAddedPage } from 'types/Page';
import { useQueryClient } from 'react-query';
import { AccountThunks } from 'modules/account/accountActions';

type Props = {
  onProfileAdded?: (profileId: number) => void;
};

const useAddNewProfileAutomatically = ({ onProfileAdded }: Props) => {
  const modalHistory = useModalHistory();
  const toast = useToast();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  function onPageAdded(addedPage: NewAddedPage) {
    modalHistory.closeLast();
    Logger.log('Profiles added', addedPage);

    const hasAddedMore = addedPage.ids.length > 1;
    const idToSelect = addedPage.ids[0];

    toast(`New ${hasAddedMore ? 'profiles' : 'profile'} successfully created`);

    UserpilotUtils.track(
      hasAddedMore
        ? UserpilotUtils.events.SOCIAL_MEDIA_PROFILES_CONNECTED
        : UserpilotUtils.events.SOCIAL_MEDIA_PROFILE_CONNECTED,
    );

    dispatch(PageThunk.requestPages());
    dispatch(AccountThunks.requestAccount());
    queryClient.invalidateQueries();

    if (idToSelect && onProfileAdded) {
      onProfileAdded(idToSelect);
    }
  }

  return {
    onPageAdded,
  };
};

export default useAddNewProfileAutomatically;
