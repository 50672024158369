import { useLocation } from 'react-router-dom';
import { PostsInternalCommunicationEmptyMessage } from './PostsInternalCommunicationEmptyMessage';

export const PostsCommunicationEmptyMessage = () => {
  const location = useLocation();
  const isPublicPost = location.pathname.includes('/public/post');

  if (isPublicPost) {
    return (
      <div className="tw-flex tw-items-center tw-justify-center tw-p-3">
        <p className="tw-text-md">{`You don't have any comments yet. Let's start collaborating!`}</p>
      </div>
    );
  }

  return <PostsInternalCommunicationEmptyMessage />;
};
