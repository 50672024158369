import React, { FC, useState } from 'react';
import { BrandLogo, Icon } from '@kontentino/ui';
import getSocialMediaTypeForBrandLogo from '../../../utils/getSocialMediaTypeForBrandLogo';
import ProfileSelectorOptionCheckIndicator from './profileSelectorOption/ProfileSelectorOptionCheckIndicator';
import ProfileSelectorSubOption from 'app/components/profileSelector/components/profileSelectorOption/ProfileSelectorSubOption';
import ProfileSelectorOptionActions from 'app/components/profileSelector/components/profileSelectorOption/ProfileSelectorOptionActions';
import { faGlobe } from '@fortawesome/pro-regular-svg-icons/faGlobe';
import clsx from 'clsx';
import PageTypeUtils from 'app/utils/pageType';
import { faFolder } from '@fortawesome/pro-regular-svg-icons/faFolder';
import { getYtLogoGuidelineSize } from 'app/utils/getytlogoguidelinesize';

type Option = {
  label: string;
  logoSrc?: string;
  socialMediaType?: number;
  isGroup?: boolean;
  isConnected?: boolean;
  isGlobal?: boolean;
};

type SubOption = Option & { id: number };

type Props = {
  option: Option;
  subOptions?: SubOption[];
  eventHandlers?: {
    onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
    onEditClick?: () => void;
    onReconnectClick?: () => void;
    onSubOptionReconnectClick?: (page: {
      id: number;
      type: number | null;
    }) => void;
  };
  isChecked: boolean;
  isDisabled?: boolean;
  dataCy?: string;
  isDemo?: boolean;
  isSingleSelect?: boolean;
  isGlobal?: boolean;
  dataName?: string;
};

const ProfileSelectorOption: FC<Props> = ({
  option,
  subOptions,
  isChecked,
  isDisabled,
  eventHandlers = {},
  dataCy,
  dataName,
  isDemo,
  isSingleSelect,
  isGlobal,
}) => {
  const [areSubOptionsOpen, setSubOptionsOpen] = useState(false);
  const { onClick, onSubOptionReconnectClick } = eventHandlers;

  return (
    <>
      <div
        className={clsx(
          'tw-overflow-hiddenn tw-group tw-mb-6 tw-flex tw-select-none tw-gap-2 hover:tw-cursor-pointer',
          {
            'tw-pointer-events-none tw-opacity-50': isDisabled,
          },
        )}
        data-cy={dataCy}
        data-name={dataName}
      >
        <div className="tw-flex tw-w-full tw-flex-row tw-flex-nowrap tw-items-center">
          <div className="tw-flex tw-w-full tw-items-center" onClick={onClick}>
            <ProfileSelectorOptionCheckIndicator
              isChecked={isChecked}
              isSingleSelect={isSingleSelect}
              dataCy={`${dataCy}-${
                isSingleSelect ? 'radio-button' : 'checkbox'
              }`}
            />
            <div className="tw-ml-3 tw-mr-2">
              <div className="tw-relative tw-flex tw-h-6 tw-items-center">
                <BrandLogo
                  src={option.logoSrc}
                  name={option.label}
                  size={28}
                  socialMediaType={getSocialMediaTypeForBrandLogo(
                    option.socialMediaType,
                  )}
                  iconSize={getYtLogoGuidelineSize(12, option.socialMediaType)}
                />
                {option.isGroup && (
                  <div className="tw-absolute tw-bottom-0 tw-right-0 tw-flex tw-rounded tw-bg-grayscale-20 tw-text-grayscale-70">
                    <Icon icon={faFolder} size={8} />
                  </div>
                )}
                {isGlobal && (
                  <Icon
                    icon={faGlobe}
                    className="tw-ml-1 tw-text-grayscale-100"
                    size="md"
                  />
                )}
              </div>
            </div>
            <div
              translate="no"
              className={clsx(
                'tw-flex tw-items-center tw-pr-1 tw-text-md tw-font-medium tw-text-grayscale-180  group-hover:tw-text-primary-100',
                {
                  'tw-text-primary-100': isChecked,
                },
              )}
            >
              <span className="tw-line-clamp-1 tw-overflow-hidden tw-text-ellipsis tw-break-all">
                {option.label}
              </span>
            </div>
          </div>
          <ProfileSelectorOptionActions
            isOptionGroup={option.isGroup}
            canShowDemoBadge={isDemo}
            eventHandlers={{
              ...eventHandlers,
              onReconnectClick:
                PageTypeUtils.isManualOnly(option.socialMediaType) ||
                ((option.isGroup || option.isGlobal) &&
                  subOptions?.every((subOptionPofile) =>
                    PageTypeUtils.isManualOnly(subOptionPofile.socialMediaType),
                  ))
                  ? undefined
                  : eventHandlers.onReconnectClick,
              onExpandToggle: !!subOptions?.length
                ? () => setSubOptionsOpen((prev) => !prev)
                : undefined,
            }}
            editButtonClassName="group-hover:tw-block tw-hidden"
            isExpanded={areSubOptionsOpen}
          />
        </div>
      </div>
      {areSubOptionsOpen && (
        <div className="tw-mb-6 tw-flex tw-w-full tw-flex-col tw-gap-6 ">
          {subOptions?.map((subOption, index) => (
            <div className=" tw-pl-4" key={`sub-option-${index}`}>
              <ProfileSelectorSubOption
                option={subOption}
                onReconnectClick={
                  PageTypeUtils.isManualOnly(subOption.socialMediaType) ||
                  subOption.isGlobal
                    ? undefined
                    : onSubOptionReconnectClick && !subOption.isConnected
                    ? () =>
                        onSubOptionReconnectClick({
                          id: subOption.id,
                          type: subOption.socialMediaType ?? null,
                        })
                    : undefined
                }
              />
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default ProfileSelectorOption;
