import React, { FC, ReactNode } from 'react';
import {
  WIDGET_VARIANT_PROPERTIES,
  WidgetVariant,
} from 'app/modules/posts/components/accordionWidget/constants/widgetVariant';
import clsx from 'clsx';

type Props = {
  variant?: WidgetVariant;
  message?: ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

const AccordionWidgetEmptyMessage: FC<Props> = ({
  variant,
  message,
  className,
  ...rest
}) => {
  const widget = variant ? WIDGET_VARIANT_PROPERTIES[variant] : undefined;

  return (
    <div
      className={clsx(
        'tw-flex tw-h-full tw-items-center tw-justify-center',
        className,
      )}
      {...rest}
    >
      <span className="tw-text-sm tw-font-regular tw-text-grayscale-100">
        {message ?? widget?.emptyMessage ?? `You don't have any content yet.`}
      </span>
    </div>
  );
};

export default AccordionWidgetEmptyMessage;
