import React, { FC } from 'react';
import { Avatar, Icon } from '@kontentino/ui';
import { faBarsSort } from '@fortawesome/pro-regular-svg-icons/faBarsSort';
import Link from 'components/shared/Link';
import { modalRoutes } from 'constants/routes';
import PublicUtils from 'components/publicPreviews/publicUtils';
import Logo from 'components/navigation/verticalNavigation/Logo';

const PublicMobileNavigation: FC = () => {
  return (
    <>
      <div className="tw-flex tw-h-[56px] tw-w-full tw-items-center tw-justify-between tw-border-b-[1px] tw-border-solid tw-border-grayscale-5 tw-bg-white tw-px-4 tw-py-1 sm:tw-hidden">
        <div className="tw-flex tw-items-center tw-justify-between tw-gap-3">
          <Link
            to={{
              hash: modalRoutes.PUBLIC_INVITE_USER,
            }}
          >
            <Icon icon={faBarsSort} />
          </Link>
          <div>
            <Logo
              linkProps={{
                'data-name': 'public-link-logo',
                isRouterLink: false,
                to: 'https://www.kontentino.com/',
              }}
              className={'tw-flex-shrink-0 !tw-p-0'}
              data-cy="navigation-link-default"
            />
          </div>
        </div>

        <div className="tw-flex tw-items-center tw-justify-between tw-gap-3">
          <Link
            to={{
              hash: modalRoutes.PUBLIC_INVITE_USER,
            }}
          >
            <Avatar size={30} name={PublicUtils.publicMock.user.name} />
          </Link>
        </div>
      </div>
    </>
  );
};

export default PublicMobileNavigation;
