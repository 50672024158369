import { get, post } from 'api/client';
import { User, InviteUserParams, InviteUserStatus } from 'types/User';
import env from 'config';
import { UserPermission } from 'constants/userPermission';
import { jsonToFormData } from 'utils/formData';

type RequestInvitationParams = {
  name: string;
  email: string;
  userEmail: string;
  accountUsername: string;
  token: string;
};

const UserApi = {
  getData(): Promise<User> {
    return get('/api/ajaxGetUserData');
  },
  getPermissions(): Promise<UserPermission[]> {
    return get<UserPermission[]>('/user/ajaxGetPermissions');
  },
  requestInvitation(params: RequestInvitationParams): Promise<boolean> {
    return get('/user/requestInvitation', {
      baseURL: env.PUBLIC_API_SERVICE_ENDPOINT,
      params,
    });
  },

  sendInvitation(params: InviteUserParams): Promise<
    InviteUserParams & {
      id: number;
      status: InviteUserStatus;
    }
  > {
    const formData = jsonToFormData({
      email: params.email,
      role: params.role,
      name: params.name || params.email,
      surname: params.surname || '',
    });

    return post('/user/ajaxFormUser', formData);
  },

  assignProfilesToUser(params: {
    userId: number;
    pageIds: string[];
  }): Promise<{ status: boolean }> {
    const formData = jsonToFormData({
      userId: params.userId.toString(),
      pageIds: JSON.stringify(params.pageIds),
      no_email: 1,
    });

    return post('/user/ajaxAddPagesToUser', formData);
  },

  assignProfileGroupsToUser(params: {
    userId: number;
    profileGroupIds: string[];
  }): Promise<{ status: boolean }> {
    const formData = jsonToFormData({
      user_id: params.userId.toString(),
      preset_ids: params.profileGroupIds.join(','),
    });

    return post('/presets/ajaxAddPresetsToUser', formData);
  },
};

export default UserApi;
