import React, { FC } from 'react';
import { NavigationItem } from '../utils/libs';
import UserPermissionGate from 'components/UserPermissionGate';
import Link from 'components/shared/Link';
import { getNavigationItemUrl } from '../verticalNavigation/mainMenu/MenuItems';
import clsx from 'clsx';
import { Icon } from '@kontentino/ui';

type Props = {
  menuItems: NavigationItem[];
  activeMenuItem?: NavigationItem;
  onClose: () => void;
};

const MobileNavigationMenu: FC<Props> = ({
  menuItems,
  activeMenuItem,
  onClose,
}) => {
  return (
    <div className="tw-flex tw-h-full tw-w-full tw-flex-col tw-items-start tw-justify-start">
      {menuItems
        .filter((item) => !item.isDisabled)
        .map((item) => (
          <UserPermissionGate
            key={item.key}
            scopes={item.permissionsScope}
            exact={false}
          >
            <Link
              onClick={(e) => {
                onClose();
                if (!(item.url || item.routerUrl)) {
                  e.preventDefault();
                }
              }}
              isRouterLink={!!item.routerUrl}
              to={getNavigationItemUrl(item)}
              className="tw-w-full"
              data-name={`mobile-navigation-link-${item.key}`}
              data-cy={`mobile-navigation-link-${item.key}`}
            >
              <span
                className={clsx(
                  'tw-flex tw-items-center tw-gap-2 tw-py-4 tw-pl-5',
                  {
                    'tw-bg-grayscale-5 tw-text-primary-100':
                      activeMenuItem?.key === item.key,
                  },
                )}
              >
                {item.icon && <Icon icon={item.icon} size="lg" />}
                <span>{item.mobileLabel || item.label}</span>
              </span>
            </Link>
          </UserPermissionGate>
        ))}
    </div>
  );
};

export default MobileNavigationMenu;
