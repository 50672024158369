import React, { FC, useRef } from 'react';
import { PageData } from 'types/Page';
import { BrandLogo, Checkbox, Chip, Select } from '@kontentino/ui';
import { components, MultiValue } from 'react-select';
import getSocialMediaTypeForBrandLogo from 'app/utils/getSocialMediaTypeForBrandLogo';
import clsx from 'clsx';
import { useOutsideClick } from 'utils/hooks/useOutsideClick';

const ALL_OPTION = {
  label: 'Select all',
  value: -1,
  page: undefined,
};

export type ProfileOptionType = {
  label: string;
  value: number;
  page?: PageData;
};

type SelectedOptionType = {
  label: string;
  value: number;
  page: PageData;
};

type ProfileSelectProps = {
  label: string;
  options: ProfileOptionType[];
  value: number[];
  onChange: (value: number[]) => void;
  isDisabled?: boolean;
  isMenuOpen: boolean;
  onMenuOpen: () => void;
  onMenuClose: () => void;
  noOptionsMessage: string;
  valueContainerLabel: string;
};

const ProfileSelect: FC<ProfileSelectProps> = ({
  label,
  options,
  value,
  onChange,
  isDisabled,
  isMenuOpen,
  onMenuOpen,
  onMenuClose,
  noOptionsMessage,
  valueContainerLabel,
}) => {
  const isAllSelected = useRef(false);
  const { elementRef, addListener, removeListener } =
    useOutsideClick<HTMLDivElement>(onMenuClose);

  const handleToggleMenu = () => {
    if (isMenuOpen) {
      onMenuClose();
      removeListener();
    } else {
      onMenuOpen();
      addListener();
    }
  };

  const handleChange = (selectedOptions: ProfileOptionType[]) => {
    const isAllNoneSelected = selectedOptions.some(
      (option) => option.value === ALL_OPTION.value,
    );

    if (isAllNoneSelected) {
      const newValue = !isAllSelected.current
        ? options.filter((opt) => opt.page).map((opt) => opt.value)
        : [];
      onChange(newValue);
      isAllSelected.current = !isAllSelected.current;
    } else {
      const newValue = selectedOptions
        .filter((option) => option.page)
        .map((option) => option.value);
      onChange(newValue);
      isAllSelected.current =
        newValue.length === options.filter((opt) => opt.page).length;
    }
  };

  const selectedOptions = value
    ?.map((id) => {
      const option = options.find((opt) => opt.value === id);
      return option || null;
    })
    .filter(
      (opt): opt is SelectedOptionType =>
        opt !== null && opt.page !== undefined,
    );

  const customComponents = {
    ValueContainer: (containerProps: any) => {
      const selectedCount = containerProps.getValue().length;

      return (
        <components.ValueContainer {...containerProps}>
          <div
            className="tw-flex tw-w-full tw-items-center tw-gap-x-2 hover:tw-cursor-pointer"
            onClick={handleToggleMenu}
            data-cy="assign-profiles-select"
          >
            <Chip data-cy="profiles-selected-count">{selectedCount}</Chip>
            <span>{valueContainerLabel}</span>
          </div>
        </components.ValueContainer>
      );
    },
    Option: (optionProps: any) => {
      const isSelected = isAllSelected.current || optionProps.isSelected;

      return (
        <components.Option
          {...optionProps}
          className={clsx(optionProps.className, {
            '!tw-bg-primary-10': isSelected,
          })}
        >
          <div
            className="tw-flex tw-items-center tw-gap-x-2"
            data-cy="profile-option"
          >
            <Checkbox
              checked={isSelected}
              size="small"
              onChange={() => null}
              data-cy="profile-option-checkbox"
            />

            {optionProps.data.page && (
              <BrandLogo
                src={optionProps.data.page.logo?.src}
                name={optionProps.data.label}
                size={24}
                socialMediaType={getSocialMediaTypeForBrandLogo(
                  optionProps.data.page.type,
                )}
              />
            )}
            <div className="tw-truncate">{optionProps.data.label}</div>
          </div>
        </components.Option>
      );
    },
  };

  return (
    <div ref={elementRef}>
      <label className="tw-text-gray-700 tw-mb-1 tw-block tw-text-sm tw-font-medium">
        {label}{' '}
        <span className="tw-ml-1 tw-font-regular tw-text-grayscale-100">
          (optional)
        </span>
      </label>
      <Select
        isMulti
        menuPlacement="auto"
        isCreatable={false}
        isDisabled={isDisabled}
        options={options.length > 0 ? [ALL_OPTION, ...options] : options}
        getOptionValue={(option: ProfileOptionType) => String(option.value)}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        menuIsOpen={isMenuOpen}
        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
        components={customComponents}
        onChange={(selected: MultiValue<ProfileOptionType>) =>
          handleChange(selected as ProfileOptionType[])
        }
        value={selectedOptions}
        noOptionsMessage={() => noOptionsMessage}
        menuPortalTarget={document.body}
        styles={{
          menuPortal: (styles) => ({
            ...styles,
            zIndex: 1010,
          }),
        }}
      />
    </div>
  );
};

export default ProfileSelect;
