import React, { FC } from 'react';
import { modalRoutes } from 'constants/routes';
import UserPermissionGate from 'components/UserPermissionGate';
import { UserPermission } from 'constants/userPermission';
import ModalRoute from 'context/ModalRouter';
import { AssociateProfileModal } from 'app/modules/posts/components/PostsAssociateProfileModal';

type Props = {};

const SettingsModals: FC<Props> = (props) => {
  return (
    <>
      <UserPermissionGate scopes={UserPermission.PAGE_CREATE}>
        <ModalRoute
          path={modalRoutes.ASSOCIATE_POSTS_PROFILE}
          component={AssociateProfileModal}
        />
      </UserPermissionGate>
    </>
  );
};

export default SettingsModals;
