import React, { FC, ReactNode } from 'react';
import { Icon } from '@kontentino/ui';
import { faShareNodes } from '@fortawesome/pro-regular-svg-icons/faShareNodes';
type Props = {
  message: string;
  ctaButton?: ReactNode;
};

const ProfileSelectorInfoMessage: FC<Props> = ({ message, ctaButton }) => {
  return (
    <div className="tw-flex tw-h-full tw-flex-col tw-items-center tw-justify-center">
      <div className="tw-flex tw-h-[3rem] tw-w-[3rem] tw-flex-shrink-0 tw-items-center tw-justify-center tw-rounded-[50%] tw-bg-primary-10">
        <Icon icon={faShareNodes} size={28} className="tw-text-primary-100" />
      </div>
      <div className="tw-mt-3 tw-flex tw-flex-col tw-items-center">
        <span className="tw-text-md tw-font-medium">{message}</span>
        {ctaButton}
      </div>
    </div>
  );
};

export default ProfileSelectorInfoMessage;
