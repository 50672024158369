type UserAvatar = {
  id: number;
  src: string;
  width: number;
  height: number;
};

// Default type is empty string because of initial state
export type UserRole =
  | 'admin'
  | 'manager'
  | 'client'
  | 'translator'
  | 'artist'
  | 'media_agency'
  | '';

export type UserLastPostsQueryParams = {
  dateFrom: string;
  dateTo: string;
  orderDirection: string;
  profiles: number[];
  projects: number[];
  assignedUsers?: number[];
  labels?: number[];
  profileGroups?: number[];
  pseudoTypes?: string[];
  statuses?: number[];
};

export type User = {
  id: number;
  localId: number;
  name: string;
  surname: string;
  role: UserRole;
  email: string;
  timezone: string;
  useMeridianTime: boolean;
  hasDevices: boolean;
  devicesInfo: {
    ios: boolean;
    android: boolean;
  };
  companyPhone: string;
  avatar: UserAvatar | null;
  color: string;
  regDate: string;
  intercom: IntercomUser | null;
  features_toggle: {
    new_analytics: boolean;
    new_calendar: boolean;
  };
  lastPostsQueryParams: UserLastPostsQueryParams;
};

export type IntercomUser = {
  user_id: string;
  identity: string;
  app_id: string;
  created_at: number;
  name: string;
  email: string;
  company: {
    id: string;
    name: string;
    remote_created_at: number;
  };
  avatar: {
    image_url: string;
  };
  user_hash: string;
  role: string;
  android_app: boolean;
  ios_app: boolean;
  usingNewAnalytics: boolean;
  usingNewCalendar: boolean;
};

export type UserAssignedToPage = {
  id: number;
  name: string;
  role: string;
  avatar?: UserAvatar;
};

export type MentionableUser = Pick<User, 'name' | 'id' | 'role'> & {
  last_name: string;
  avatar: UserAvatar | null;
  is_demo: boolean;
};

export type PageUserWithMobileApp = Pick<User, 'name' | 'id'> & {
  avatar: string;
  mobile_app_types: string[];
};

export type InviteUserParams = {
  name?: string;
  role: string;
  surname?: string;
  email: string;
};

export enum InviteUserStatus {
  Created = 'created',
  Existed = 'existed',
  Restored = 'restored',
}
