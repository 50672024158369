import React, { FC } from 'react';
import VerticalNavigation from 'components/navigation/VerticalNavigation';
import useNavigation from 'components/navigation/hooks/useNavigation';

type Props = {};

const Navigation: FC<Props> = () => {
  const { user, items, activeMenuItem } = useNavigation();

  return (
    <VerticalNavigation
      menuItems={items}
      activeItem={activeMenuItem}
      user={user}
    />
  );
};

export default Navigation;
