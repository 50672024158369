import React, { FC, useMemo } from 'react';
import VerticalNavigation from 'components/navigation/VerticalNavigation';
import { PublicUtils } from 'components/publicPreviews/publicUtils';
import { PostPreviewFooter } from 'components/publicPreviews/post/PostPreviewFooter';
import PublicPostPreviewsList from 'components/publicPreviews/post/PublicPostPreviewsList';
import { parseQueryParamToNumbers } from 'utils/url';
import { modalRoutes } from 'constants/routes';
import PublicInviteUser from 'pages/PublicInviteUser';
import ModalRoute, { ModalRouteProps } from 'context/ModalRouter';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { Alert } from '@kontentino/ui';
import { useTranslation } from 'react-i18next';
import PublicMobileNavigation from 'app/modules/public/components/PublicMobileNavigation';

export const PublicPostOverview: FC = () => {
  const { postId } = useParams<{ postId: string | undefined }>();
  const [search] = useSearchParams();
  const { t } = useTranslation();

  const { ids, token } = useMemo(() => {
    const ids: number[] = [
      ...parseQueryParamToNumbers(postId),
      ...parseQueryParamToNumbers(search.get('ids')),
    ];

    const token = search.get('token') || '';

    return { ids, token };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PublicMobileNavigation />
      <div className="tw-flex tw-flex-1">
        <VerticalNavigation
          isPublic
          menuItems={PublicUtils.publicMock.menuItems}
          user={PublicUtils.publicMock.user}
          logoLinkProps={{
            'data-name': 'public-link-logo',
            isRouterLink: false,
            to: 'https://www.kontentino.com/',
          }}
        />

        <div className="tw-flex tw-h-[calc(100vh-56px)] tw-flex-1 tw-flex-col tw-overflow-hidden sm:tw-ml-[64px] sm:tw-h-screen">
          {!!token && ids.length > 0 ? (
            <PublicPostPreviewsList token={token} ids={ids} />
          ) : (
            <div className="tw-flex tw-flex-grow tw-items-center tw-justify-center">
              <Alert variant="danger" className="!tw-w-auto">
                {t('publicPostsSomethingWentWrong')}
              </Alert>
            </div>
          )}
          {ids.length === 1 && <PostPreviewFooter />}
        </div>
        <ModalRoute
          path={modalRoutes.PUBLIC_INVITE_USER}
          component={useMemo(
            () => (props: ModalRouteProps) =>
              <PublicInviteUser token={token} {...props} />,
            [token],
          )}
        />
      </div>
    </>
  );
};
