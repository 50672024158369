import React, { FC } from 'react';
import { CartesianGrid } from 'recharts';
import GraphLegend from 'app/modules/insights/modules/pagePerformance/components/graph/GraphLegendContent';
import GraphAxis from 'app/modules/insights/modules/pagePerformance/components/graph/GraphAxis';
import GraphContent from './graph/GraphContent';
import GraphGradient from './graph/GraphGradient';
import {
  GraphData,
  GraphVariant,
  MetricGraphLegendLabels,
} from 'app/modules/insights/types/PagePerformance';
import { colors } from '@kontentino/ui';
import { FilterState } from 'app/modules/insights/hooks/useFilterState';
import { getGraphTooltipLabels } from 'app/modules/insights/modules/pagePerformance/utils/getGraphLabels';
import GraphTooltip from 'app/modules/insights/modules/pagePerformance/components/graph/GraphTooltip';
import GraphComponent from 'app/modules/insights/modules/pagePerformance/components/graph/GraphComponent';
import HeatMapGraphComponent from 'app/modules/insights/modules/pagePerformance/components/graph/HeatMapGraphComponent';

type Props = {
  title: string;
  filterState: Pick<
    FilterState,
    'startDate' | 'endDate' | 'comparedEndDate' | 'comparedStartDate'
  >;
  variant: GraphVariant;
  data: GraphData[];
  gridData?: GraphData[][];
  expanded: boolean;
  isCompared?: boolean;
  legendLabels?: MetricGraphLegendLabels;
  description?: string;
  showTooltip?: boolean;
  isCombinedMetric?: boolean;
  combinedMetricComparedData?: GraphData[];
  width?: number | string;
  height?: number | string;
  useMeridianTime?: boolean;
};

const GenericGraph: FC<Props> = ({
  filterState,
  data,
  gridData,
  expanded,
  variant,
  isCompared = false,
  legendLabels,
  showTooltip = true,
  isCombinedMetric,
  title,
  combinedMetricComparedData,
  width = '100%',
  height,
  description,
  useMeridianTime,
}) => {
  const dynamicData =
    isCombinedMetric && isCompared ? combinedMetricComparedData ?? data : data;

  const tooltipLabels = getGraphTooltipLabels({
    filterState,
    legendLabels,
    isCompared,
    isCombinedMetric,
  });

  if (variant === 'heatmap') {
    return (
      <HeatMapGraphComponent
        gridData={gridData ?? []}
        expanded={expanded}
        description={description}
        useMeridianTime={useMeridianTime}
      />
    );
  }

  return (
    <GraphComponent
      data={dynamicData}
      variant={variant}
      width={width}
      height={height}
    >
      <CartesianGrid vertical strokeDasharray="2" stroke={colors.gray05} />
      {showTooltip &&
        GraphTooltip({
          title,
          tooltipLabels,
          isCompared,
          isCombinedMetric,
          graphVariant: variant,
        })}
      {((isCombinedMetric && !!legendLabels) ||
        (isCompared && !!legendLabels)) &&
        GraphLegend({
          legendLabels: legendLabels,
          isCombinedMetric,
          isCompared,
        })}
      {variant === 'area' && GraphGradient({})}
      {GraphAxis({
        graphVariant: variant,
        dataKey: 'date',
      })}
      {GraphContent({
        variant,
        dataKey: 'value',
        secondDataKey: isCombinedMetric ? 'secondValue' : undefined,
        isCompared: isCompared,
        comparedDataKey: isCompared ? 'comparedValue' : undefined,
        comparedSecondDataKey:
          isCompared && isCombinedMetric ? 'comparedSecondValue' : undefined,
      })}
    </GraphComponent>
  );
};

export default GenericGraph;
