import React, { FC, useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import DatePickerDropdown from 'app/components/datepicker-dropdown';
import DatePickerHeaderCustomPeriod from 'app/components/datepicker-dropdown/custom-header/DatePickerHeaderCustomPeriod';
import DatePickerFooter from 'app/components/datepicker-dropdown/DatePickerFooter';
import { isDateWithinRange } from 'app/components/datepicker-dropdown/utils';
import useSetState from 'utils/hooks/useSetState';
import { getRangePlaceholderLabel } from 'app/components/datepicker-dropdown/utils/placeholder';

type Props = {
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  onChange: (
    {
      startDate,
      endDate,
    }: {
      startDate: Dayjs | null;
      endDate: Dayjs | null;
    },
    preset?: string,
  ) => void;
};

const RangeDatePicker: FC<Props> = ({ startDate, endDate, onChange }) => {
  const [error, setError] = useSetState<{
    startDate: boolean;
    endDate: boolean;
  }>({
    startDate: true,
    endDate: true,
  });
  const [isOpen, setIsOpen] = useState(false);
  const [startDateTemp, setStartDateTemp] = useState<Dayjs | null>(null);
  const [endDateTemp, setEndDateTemp] = useState<Dayjs | null>(null);

  const onCancelCustomPeriod = () => {
    setStartDateTemp(startDate);
    setEndDateTemp(endDate);
  };

  const onDateRangeSubmit = () => {
    onChange(
      {
        startDate: startDateTemp ?? null,
        endDate: endDateTemp ?? null,
      },
      undefined,
    );
    setIsOpen(false);
  };

  const onRangeSelect = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;

    setStartDateTemp(start ? dayjs(start) : null);
    setEndDateTemp(end ? dayjs(end) : null);
  };

  const selectedDate = startDate ?? null;

  useEffect(() => {
    if (startDate && endDate) {
      setError({
        startDate: startDate ? isDateWithinRange(startDate, null, null) : false,
        endDate: endDate ? isDateWithinRange(endDate, null, null) : false,
      });
    }
  }, [startDate, endDate, setError]);

  return (
    <DatePickerDropdown
      contentClassname="tw-min-w-[300px]"
      placeholderClassname="tw-h-[40px]"
      isOpen={isOpen}
      onOpenChange={setIsOpen}
      label={getRangePlaceholderLabel({
        startDateTemp: startDateTemp ?? startDate,
        endDateTemp: endDateTemp ?? endDate,
      })}
      selected={selectedDate?.toDate() ?? null}
      startDate={startDateTemp?.toDate() ?? null}
      endDate={endDateTemp?.toDate() ?? null}
      selectsRange={true}
      onChange={(val) => {
        onRangeSelect(val as [Date | null, Date | null]);
      }}
      renderCustomHeader={(headerProps) => (
        <DatePickerHeaderCustomPeriod
          {...headerProps}
          error={error}
          startDate={startDateTemp ?? startDate}
          endDate={endDateTemp ?? endDate}
          onStartDateChange={(date) => setStartDateTemp(date)}
          onEndDateChange={(date) => setEndDateTemp(date)}
        />
      )}
    >
      <DatePickerFooter
        isCancelDisabled={
          startDate === startDateTemp && endDate === endDateTemp
        }
        onDateRangeSubmit={onDateRangeSubmit}
        onRangeSelectCancel={onCancelCustomPeriod}
      />
    </DatePickerDropdown>
  );
};

export default RangeDatePicker;
