import { FC } from 'react';
import { useMutation, useQuery } from 'react-query';
import { queryKey } from 'constants/queryKey';
import TagInputWithSuggestions from 'components/shared/TagInputWithSuggestions';
import { PostLabel } from 'types/Post';
import { useToast } from 'app/hooks/useToast';
import { Module } from 'config';
import { Button, colors, Field, TextLink } from '@kontentino/ui';
import TagsApi from 'api/tags';
import UserpilotUtils from 'app/utils/userpilot';
import DataLayer from 'app/utils/dataLayer';
import { useTranslation } from 'react-i18next';
import StringUtils from 'utils/string';
import { PermissionsGate } from '@kontentino/react-permissions-gate';
import { UserPermission } from 'constants/userPermission';

type Props = {
  pageId: number | null;
  value: PostLabel[];
  onChange: (fn: (tags: PostLabel[]) => PostLabel[]) => void;
  isDisabled?: boolean;
  onMenuOpen?(): void;
  onFocus?(): void;
  onBlur?(): void;
};

const MAX_LABEL_LENGTH = 40;
const RECENT_TAGS_COUNT = 6;

const PageTagsSelect: FC<Props> = ({
  isDisabled,
  value,
  pageId,
  onChange,
  onMenuOpen,
  onFocus,
  onBlur,
}) => {
  const toast = useToast();
  const { t } = useTranslation();
  const availableTags = useQuery(
    queryKey.pageTags(pageId),
    pageId ? () => TagsApi.getAll(pageId) : () => [],
    { enabled: !!pageId },
  );

  const recentTags = useQuery(
    queryKey.recentTags(pageId),
    pageId ? () => TagsApi.getRecent(pageId, RECENT_TAGS_COUNT) : () => [],
    { enabled: !!pageId },
  );

  const createTag = useMutation(
    (tagName: string) => {
      if (pageId) {
        return TagsApi.create({
          pageId: pageId,
          name: tagName,
        });
      }
      return Promise.reject('No pageId provided');
    },
    {
      onSuccess,
      onError: removeTempTags,
    },
  );

  function onSuccess(createdTag: PostLabel) {
    onChange((prev) =>
      prev.map((tag) => (createdTag.name === tag.name ? createdTag : tag)),
    );
    availableTags.refetch();
    UserpilotUtils.track(UserpilotUtils.events.LABEL_CREATED);
  }

  function removeTempTags() {
    onChange((prev) => prev.filter((tag) => tag.id > 0));
  }

  const handleCreateTag = (name: string) => {
    if (name.length > Module.Pages.LABEL_MAX_LENGTH) {
      toast(
        `Label can't be longer than ${Module.Pages.LABEL_MAX_LENGTH} characters.`,
        'warning',
      );
      return;
    }
    if (name.trimEnd().length < Module.Pages.LABEL_MIN_LENGTH) {
      toast(
        `Label must have at least ${Module.Pages.LABEL_MIN_LENGTH} characters.`,
        'warning',
      );
      return;
    }

    const temporaryLabel: PostLabel = {
      id: new Date().getMilliseconds() * -1,
      name: name.trim(),
      color: colors.gray40,
    };

    onChange((prev) => [...prev, temporaryLabel]);

    createTag.mutate(name);
  };

  return (
    <>
      <TagInputWithSuggestions
        onFocus={onFocus}
        onBlur={onBlur}
        value={value}
        options={availableTags.data ?? []}
        isDisabled={
          isDisabled || availableTags.isLoading || availableTags.isError
        }
        onChange={(tags) => {
          onChange((prev) => {
            const isTagAdded = prev.length < tags.length;
            if (isTagAdded) {
              UserpilotUtils.track(
                UserpilotUtils.events.USER_SELECTED_LABEL_FROM_SUGGESTIONS,
              );
            }
            return tags;
          });
        }}
        onCreate={handleCreateTag}
        onMenuOpen={onMenuOpen}
      />
      {!!recentTags.data?.length && (
        <PermissionsGate
          scopes={[UserPermission.TAG_CREATE, UserPermission.TAG_LIST]}
        >
          <div
            className="tw-mt-2 tw-flex tw-items-baseline tw-space-x-1"
            data-name="recent-label-list"
            data-cy="recent-label-list"
          >
            <Field.Caption>{t('recent')}:</Field.Caption>
            <div className="tw-flex tw-flex-wrap tw-space-x-1">
              {recentTags.data.map((tag, index) => (
                <TextLink key={tag.id}>
                  <Button
                    data-name="recent-label"
                    data-cy="recent-label"
                    variant="plain"
                    onClick={() => {
                      if (value.some((t) => t.id === tag.id)) return;
                      onChange((prev) => [...prev, tag]);
                      DataLayer.push({
                        event: DataLayer.events.USER_SELECTED_LABEL_FROM_RECENT,
                      });
                    }}
                    className="tw-mr-1 tw-break-words tw-text-sm"
                  >
                    {StringUtils.truncate(tag.name, MAX_LABEL_LENGTH)}
                    {index < recentTags.data.length - 1 && `,`}
                  </Button>
                </TextLink>
              ))}
            </div>
          </div>
        </PermissionsGate>
      )}
    </>
  );
};

export default PageTagsSelect;
