import { OrderedMetricCard, OrderedMetricCardGroup } from '../types/graphs';
import { TYPE } from '@kontentino/kontentino-constants/Pages';
import {
  FB_METRICS_PROPERTIES,
  IG_METRICS_PROPERTIES,
  LI_METRICS_PROPERTIES,
} from '../utils/metricProperty';

const defaultPagePerformanceMetricsOrder: {
  [pageType: number]: OrderedMetricCard[];
} = {
  [TYPE.FACEBOOK]: [
    { metricKey: FB_METRICS_PROPERTIES.audience.key, expanded: true },
    { metricKey: FB_METRICS_PROPERTIES.engagement.key, expanded: true },
    { metricKey: FB_METRICS_PROPERTIES.engaged_users.key, expanded: true },
    { metricKey: FB_METRICS_PROPERTIES.post_engagement.key, expanded: false },
    {
      metricKey: FB_METRICS_PROPERTIES.unique_page_consumption.key,
      expanded: false,
    },
    {
      metricKey: FB_METRICS_PROPERTIES.bestTimeToPostAllTime.key,
      expanded: false,
    },
    { metricKey: FB_METRICS_PROPERTIES.bestTimeToPost.key, expanded: false },
    { metricKey: FB_METRICS_PROPERTIES.page_consumption.key, expanded: false },
    { metricKey: FB_METRICS_PROPERTIES.page_impressions.key, expanded: true },
    {
      metricKey: FB_METRICS_PROPERTIES.page_unique_impressions.key,
      expanded: false,
    },
    {
      metricKey: FB_METRICS_PROPERTIES.viral_frequency_distribution.key,
      expanded: false,
    },
    {
      metricKey: FB_METRICS_PROPERTIES.paid_vs_organic_unique.key,
      expanded: false,
    },
    { metricKey: FB_METRICS_PROPERTIES.viral_vs_nonviral.key, expanded: false },
    {
      metricKey: FB_METRICS_PROPERTIES.viral_vs_nonviral_unique.key,
      expanded: false,
    },
    { metricKey: FB_METRICS_PROPERTIES.paid_vs_organic.key, expanded: false },
  ],
  [TYPE.INSTAGRAM]: [
    {
      metricKey: IG_METRICS_PROPERTIES.followerDemographics.key,
      expanded: false,
    },
    { metricKey: IG_METRICS_PROPERTIES.reachedAudience.key, expanded: false },
    { metricKey: IG_METRICS_PROPERTIES.engagedAudience.key, expanded: false },
    { metricKey: IG_METRICS_PROPERTIES.reach.key, expanded: false },
    { metricKey: IG_METRICS_PROPERTIES.bestTimeToPost.key, expanded: false },
    {
      metricKey: IG_METRICS_PROPERTIES.bestTimeToPostAllTime.key,
      expanded: false,
    },
    // { metricKey: IG_METRICS_PROPERTIES.engagement.key, expanded: false },
    // { metricKey: IG_METRICS_PROPERTIES.impressions.key, expanded: false },
    // { metricKey: IG_METRICS_PROPERTIES.clicks.key, expanded: false },
  ],
  [TYPE.LINKEDIN]: [
    { metricKey: LI_METRICS_PROPERTIES.followers.key, expanded: true },
    { metricKey: LI_METRICS_PROPERTIES.engagement.key, expanded: false },
    { metricKey: LI_METRICS_PROPERTIES.followerGrowth.key, expanded: false },
    { metricKey: LI_METRICS_PROPERTIES.impressions.key, expanded: false },
    { metricKey: LI_METRICS_PROPERTIES.bestTimeToPost.key, expanded: false },
    {
      metricKey: LI_METRICS_PROPERTIES.bestTimeToPostAllTime.key,
      expanded: false,
    },
  ],
};

export const getDefaultPagePerformanceMetricsOrder = (pageType: number) => {
  return defaultPagePerformanceMetricsOrder[pageType];
};

export const groupedPagePerformanceMetrics: {
  [pageType: number]: (Pick<
    OrderedMetricCardGroup,
    'title' | 'comparisonTitle'
  > & {
    metricKeys: string[];
  })[];
} = {
  [TYPE.FACEBOOK]: [
    {
      title: null,
      comparisonTitle: null,
      metricKeys: [
        FB_METRICS_PROPERTIES.audience.key,
        FB_METRICS_PROPERTIES.bestTimeToPostAllTime.key,
        FB_METRICS_PROPERTIES.bestTimeToPost.key,
      ],
    },
    {
      title: 'pagePerformance.pageEngagement',
      comparisonTitle: 'pagePerformance.pageEngagement',
      metricKeys: [
        FB_METRICS_PROPERTIES.engagement.key,
        FB_METRICS_PROPERTIES.engaged_users.key,
        FB_METRICS_PROPERTIES.post_engagement.key,
        FB_METRICS_PROPERTIES.unique_page_consumption.key,
        FB_METRICS_PROPERTIES.page_consumption.key,
      ],
    },
    {
      title: 'pagePerformance.pageImpressions',
      comparisonTitle: 'pagePerformance.pageImpressions',
      metricKeys: [
        FB_METRICS_PROPERTIES.page_impressions.key,
        FB_METRICS_PROPERTIES.page_unique_impressions.key,
        FB_METRICS_PROPERTIES.viral_frequency_distribution.key,
        FB_METRICS_PROPERTIES.paid_vs_organic_unique.key,
        FB_METRICS_PROPERTIES.viral_vs_nonviral.key,
        FB_METRICS_PROPERTIES.viral_vs_nonviral_unique.key,
        FB_METRICS_PROPERTIES.paid_vs_organic.key,
      ],
    },
  ],
  [TYPE.INSTAGRAM]: [
    {
      title: 'pagePerformance.allTime',
      comparisonTitle: 'pagePerformance.allTime',
      metricKeys: [
        IG_METRICS_PROPERTIES.followerDemographics.key,
        IG_METRICS_PROPERTIES.reachedAudience.key,
        IG_METRICS_PROPERTIES.engagedAudience.key,
        IG_METRICS_PROPERTIES.bestTimeToPostAllTime.key,
      ],
    },
    {
      title: 'pagePerformance.period',
      comparisonTitle: 'pagePerformance.comparison',
      metricKeys: [
        IG_METRICS_PROPERTIES.bestTimeToPost.key,
        IG_METRICS_PROPERTIES.reach.key,
        // IG_METRICS_PROPERTIES.engagement.key,
        // IG_METRICS_PROPERTIES.impressions.key,
        IG_METRICS_PROPERTIES.emailContacts.key,
        // IG_METRICS_PROPERTIES.clicks.key,
      ],
    },
  ],
  [TYPE.LINKEDIN]: [
    {
      title: 'pagePerformance.allTime',
      comparisonTitle: 'pagePerformance.allTime',
      metricKeys: [
        LI_METRICS_PROPERTIES.followers.key,
        LI_METRICS_PROPERTIES.bestTimeToPostAllTime.key,
      ],
    },
    {
      title: 'pagePerformance.period',
      comparisonTitle: 'pagePerformance.comparison',
      metricKeys: [
        LI_METRICS_PROPERTIES.engagement.key,
        LI_METRICS_PROPERTIES.followerGrowth.key,
        LI_METRICS_PROPERTIES.impressions.key,
        LI_METRICS_PROPERTIES.bestTimeToPost.key,
      ],
    },
  ],
};

export const getGroupedPagePerformanceMetrics = ({
  pageType,
  metrics,
}: {
  pageType: number;
  metrics: OrderedMetricCard[];
}) => {
  const grouped: OrderedMetricCardGroup[] = [];

  groupedPagePerformanceMetrics[pageType].forEach((group) => {
    grouped.push({
      title: group.title,
      comparisonTitle: group.comparisonTitle,
      metrics: group.metricKeys
        .map((metricKey) =>
          metrics.find((metric) => metric.metricKey === metricKey),
        )
        .filter((metric) => !!metric) as OrderedMetricCard[],
    });
  });

  return grouped;
};
