import React, { FC } from 'react';
import { useQuery } from 'react-query';
import { queryKey } from 'constants/queryKey';
import PostApi from 'api/post';
import { PostPreviewVariant } from '@kontentino/kontentino-post-previews';
import { BrandLogo, Icon } from '@kontentino/ui';
import getSocialMediaTypeForBrandLogo from 'app/utils/getSocialMediaTypeForBrandLogo';
import DemoBadge from 'app/components/DemoBadge';
import WorkflowStatusBadge from 'app/components/workflowStatus/WorkflowStatusBadge';
import PostPreviewWithVariants from 'app/components/PostPreviewWithVariants';
import PostCommunicationProvider from 'app/modules/posts/modules/post/components/postCommunication/PostCommunicationProvider';
import { CommentSection } from 'components/publicPreviews/post/CommentSection';
import PreviewExpired from 'components/publicPreviews/PreviewExpired';
import Skeleton from 'react-loading-skeleton';
import PublicUtils from 'components/publicPreviews/publicUtils';
import useTrackVisibility from 'utils/hooks/useTrackVisibility';
import useLocalStorage from 'utils/hooks/useLocalStorage';
import { BrowserStorage } from 'utils/browserStorage';
import Labels from 'components/posts/detail/overview/rightSection/infoCard/Labels';
import PublicPostPreviewDownloadAssetsButton from 'components/publicPreviews/post/PublicPostPreviewDownloadAssets';
import { PublicPostPreviewConfig } from 'config/publicPostPreview';
import { PageNumberType } from '@kontentino/kontentino-constants/Pages';
import { faGlobe } from '@fortawesome/pro-regular-svg-icons/faGlobe';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight';
import { isLocalPostLocked } from 'app/modules/posts/utils/globalPosts';
import { faLockKeyhole } from '@fortawesome/pro-solid-svg-icons/faLockKeyhole';
import { getYtLogoGuidelineSize } from 'app/utils/getytlogoguidelinesize';

type Props = {
  id: number;
  token: string;
  variant?: PostPreviewVariant;
  onVariantChange?: (variant: PostPreviewVariant) => void;
};

function getAccountFromOrigin(origin: string) {
  return origin.split('.')[0].replace('https://', '');
}

const PublicPostPreview: FC<Props> = ({ id, token }) => {
  const [ref, { wasEverVisible }] = useTrackVisibility();
  const [variant, setVariant] = useLocalStorage<PostPreviewVariant>(
    BrowserStorage.keys.Posts.PreviewVariant,
    'desktop',
  );
  const { data, isLoading, isError } = useQuery(
    queryKey.publicPostPreview({ id, token }),
    () => PostApi.publicPreview(id, token),
    { enabled: wasEverVisible },
  );

  const hasAssets =
    data?.post?.id && data?.post?.attachments.cards.some((card) => card.medium);

  return (
    <div
      className="tw-space-y-8 tw-p-6"
      data-cy="public-post-preview"
      ref={ref}
    >
      {data && (
        <>
          <div className="tw-space-y-8">
            <div className="tw-mb-8 tw-flex tw-items-center tw-gap-2 tw-self-start tw-text-base tw-font-semibold">
              {data.post.globalPost && (
                <>
                  <Icon
                    icon={faGlobe}
                    className="tw-text-grayscale-100"
                    size="lg"
                  />
                  {isLocalPostLocked(data.post) && (
                    <Icon
                      icon={faLockKeyhole}
                      className="tw-text-warning-100"
                      size="lg"
                    />
                  )}

                  <Icon
                    icon={faChevronRight}
                    className="tw-text-grayscale-100"
                    size="lg"
                  />
                </>
              )}

              {data.post.isGlobal && (
                <>
                  <Icon
                    icon={faGlobe}
                    className="tw-text-grayscale-100"
                    size="lg"
                  />
                  <Icon
                    icon={faChevronRight}
                    className="tw-text-grayscale-100"
                    size="lg"
                  />
                </>
              )}
              <BrandLogo
                src={data.post.page.logo?.src ?? undefined}
                name={data.post.page.name}
                size={28}
                socialMediaType={getSocialMediaTypeForBrandLogo(
                  data.post.page.type,
                )}
                iconSize={getYtLogoGuidelineSize(12, data.post.page.type)}
              />
              <div
                className="tw-relative tw-max-w-xl tw-truncate"
                translate="no"
                data-cy="post-header-profile-name"
              >
                {data.post.page.name}
              </div>
              {data.post.page.isDemo && <DemoBadge />}
              <WorkflowStatusBadge status={data.post.status} />
            </div>
            <div className="tw-flex tw-flex-col tw-gap-6 md:tw-flex-row">
              <div className="tw-basis-1/2">
                <div className="tw-mx-auto tw-w-full tw-max-w-[500px]">
                  <PostPreviewWithVariants
                    content={{
                      ...data.post,
                      page: {
                        id: data.post.page.id,
                        name: data.post.page.name,
                        type: data.post.page.type as PageNumberType,
                        logo_src: data.post.page.logo?.src ?? null,
                      },
                      text: data.post.raw_text,
                    }}
                    variant={variant}
                    use12hourFormat={
                      !PublicUtils.publicMock.user.userMeridianTime
                    }
                    onVariantChange={setVariant}
                    isDownloadAssetsHidden
                    classNames={{ wrapper: 'tw-min-w-[200px] ' }}
                    headerAction={
                      hasAssets && (
                        <PublicPostPreviewDownloadAssetsButton
                          post={data.post}
                          token={token}
                        />
                      )
                    }
                  />
                </div>
              </div>
              <div className="tw-basis-1/2 tw-space-y-4">
                <Labels
                  post={{
                    tags: data?.post.tags ?? [],
                  }}
                  isLoading={false}
                  isError={false}
                  accordionProps={{ initialIsOpen: true }}
                />
                {!PublicPostPreviewConfig.ACCOUNTS_WITH_DISABLED_COMMENTS.includes(
                  getAccountFromOrigin(window.origin),
                ) && (
                  <PostCommunicationProvider
                    postId={data.post.id}
                    user={PublicUtils.publicMock.user}
                    enableRespondToApprovalRequest={true}
                    disableCommunicationActions
                  >
                    <CommentSection token={token} postId={data.post.id} />
                  </PostCommunicationProvider>
                )}
              </div>
            </div>
          </div>
        </>
      )}
      {isError && <PreviewExpired />}
      {isLoading && <Skeleton width="100%" height="300px" />}
    </div>
  );
};

export default PublicPostPreview;
