import React, { forwardRef } from 'react';
import { Icon, mergeTailwindClasses } from '@kontentino/ui';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown';
import clsx from 'clsx';

type Props = {
  label: string;
  isDisabled?: boolean;
  isOpen: boolean;
  isSelected?: boolean;
  className?: string;
  dataCy?: string;
};

const DatePickerPlaceholder = forwardRef<HTMLDivElement, Props>(
  ({ label, isDisabled, isOpen, isSelected, className, dataCy }, ref) => {
    return (
      <div
        data-cy={dataCy ?? 'datepicker-placeholder'}
        ref={ref}
        className={mergeTailwindClasses(
          clsx(
            'tw-relative tw-z-0 tw-flex tw-h-[36px] tw-w-[160px] tw-cursor-pointer tw-items-center tw-overflow-hidden tw-whitespace-nowrap tw-rounded tw-border-0 tw-bg-white tw-px-3 tw-text-md tw-font-regular tw-text-grayscale-180 tw-outline-none tw-ring-1 xl:tw-min-w-[200px]',
            {
              'tw-ring-primary-100': isOpen,
              'tw-ring-grayscale-30': !isOpen,
              'tw-text-grayscale-100': !isSelected,
              'tw-cursor-not-allowed tw-bg-grayscale-5 tw-text-grayscale-50':
                isDisabled,
            },
            className,
          ),
        )}
        tabIndex={0}
      >
        <span
          className={clsx('tw-text-grayscale-180', {
            'tw-text-grayscale-50': isDisabled,
          })}
        >
          {label}
        </span>
        <Icon
          icon={faChevronDown}
          className="tw-ml-auto tw-text-grayscale-100"
        />
      </div>
    );
  },
);

export default DatePickerPlaceholder;
