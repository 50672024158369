import { faUserPlus } from '@fortawesome/pro-regular-svg-icons/faUserPlus';
import { Icon } from '@kontentino/ui';
import { ActionContext, createAction } from 'actions';
import i18n from 'app/utils/i18n';
import { modalRoutes } from 'constants/routes';
import { UserPermission } from 'constants/userPermission';

export const inviteUser = (context: ActionContext) =>
  createAction(
    {
      id: 'invite-user',
      label: i18n.t('inviteUser'),
      iconBefore: <Icon icon={faUserPlus} />,
      scopes: [UserPermission.USER_CREATE],
      perform: () => {
        context.modalHistory.push({
          hash: modalRoutes.INVITE_USER,
        });
      },
    },
    context,
  );
