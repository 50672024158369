import React, { FC } from 'react';
import SelectSocialProfiles from '../selectSocialProfiles/SelectSocialProfiles';
import Modal from 'components/shared/Modal';
import { Button } from '@kontentino/ui';
import PageTypeUtils from 'app/utils/pageType';
import { TYPE } from '@kontentino/kontentino-constants/Pages';
import i18n from 'i18n';
import { useMutation } from 'react-query';
import SocialLoginApi from 'app/modules/socialLogin/api';
import { NewAddedPage } from 'types/Page';
import { SocialLoginPage } from 'types/SocialLogin';
import useSubscriptionInfo from 'app/hooks/useSubscriptionInfo';
import { modalRoutes } from 'constants/routes';
import { useModalHistory } from 'context/ModalRouter';
import { ApiClientError } from 'api/client';

type Props = {
  data: SocialLoginPage[];
  onPagesCreate: (page: NewAddedPage) => void;
  isSingleSelect?: boolean;
  connectedPagesExternalIds: string[];
  page: {
    type: number;
    id?: number;
  };
  onError?: (error: ApiClientError) => void;
};

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const GenericProfilesSelectForm: FC<Props> = ({
  data,
  onPagesCreate,
  connectedPagesExternalIds,
  isSingleSelect,
  page,
  onError,
}) => {
  const isAlreadyConnectedProfile = (id: string) =>
    connectedPagesExternalIds.includes(id);

  const [selectedProfiles, setSelectedProfiles] = React.useState<string[]>(() =>
    data
      .filter((page) => isAlreadyConnectedProfile(page.external_id))
      .map((page) => page.external_id),
  );
  const { hasReachedProfilesLimit } = useSubscriptionInfo();
  const modalHistory = useModalHistory();

  const { mutate: mutateAddProfiles, isLoading } = useMutation<
    number[],
    ApiClientError,
    {
      pageType: number;
      data: {
        timezone: string;
        pages: SocialLoginPage[];
      };
    }
  >(
    (variables) =>
      SocialLoginApi.addProfiles(variables.pageType, {
        timezone,
        pages: variables.data.pages,
      }),
    {
      onSuccess: onSuccess,
      onError,
    },
  );

  function onSuccess(data: number[]) {
    if (data) {
      onPagesCreate({
        ids: data,
        type: TYPE.FACEBOOK,
      });
    }
  }

  const handleSelect = (id: string) => {
    if (
      !selectedProfiles.includes(id) &&
      !isAlreadyConnectedProfile(id) &&
      hasReachedProfilesLimit(
        selectedProfiles.filter((id) => !isAlreadyConnectedProfile(id)).length +
          1,
      )
    ) {
      modalHistory.push({
        hash: modalRoutes.PROFILE_LIMIT_REACHED,
      });
      return;
    }

    setSelectedProfiles((prev) => {
      if (isSingleSelect) {
        return [id];
      }

      if (prev.includes(id)) {
        return prev.filter((item) => item !== id);
      }
      return [...prev, id];
    });
  };

  const handleSubmit = () => {
    const pages = selectedProfiles.map((id) =>
      data.find((profile) => profile.external_id === id),
    ) as SocialLoginPage[];

    mutateAddProfiles({
      pageType: page.type,
      data: {
        timezone,
        pages,
      },
    });
  };

  const handleSelectAll = () => {
    if (
      hasReachedProfilesLimit(
        data.filter((page) => !isAlreadyConnectedProfile(page.external_id))
          .length,
      )
    ) {
      modalHistory.push({
        hash: modalRoutes.PROFILE_LIMIT_REACHED,
      });
      return;
    }
    setSelectedProfiles(data.map((profile) => profile.external_id));
  };

  return (
    <div>
      <SelectSocialProfiles
        profiles={data.map((profile) => ({
          ...profile,
          id: profile.external_id,
          logo: profile.logo
            ? {
                src: profile.logo,
              }
            : undefined,
          type: PageTypeUtils.getNumberFromName(profile.type),
        }))}
        selectedIds={selectedProfiles}
        onSelect={(id) => handleSelect(id as string)}
        onSelectAll={handleSelectAll}
        onSelectNone={() => setSelectedProfiles([])}
      />
      <Modal.Footer className="tw-px-0">
        <Button
          disabled={selectedProfiles.length === 0}
          isLoading={isLoading}
          onClick={handleSubmit}
          data-name={`posts_add-profile-modal_${PageTypeUtils.getName(
            TYPE.FACEBOOK,
          )}_confirm`}
          className="tw-w-full"
        >
          {i18n.addProfile}
        </Button>
      </Modal.Footer>
    </div>
  );
};

export default GenericProfilesSelectForm;
