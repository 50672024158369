import { FC, useMemo } from 'react';
import dayjs from 'dayjs';
import { GraphData } from 'app/modules/insights/types/PagePerformance';
import ArrayUtils from 'app/utils/array';
import clsx from 'clsx';
import { Tooltip } from '@kontentino/ui';

type Props = {
  gridData: GraphData[][];
  expanded: boolean;
  description?: string;
  useMeridianTime?: boolean;
};

const hours = ArrayUtils.generate(24);
const days = dayjs.weekdays(true).map((day) => day.slice(0, 2));
const fullDays = dayjs.weekdays(true);

const HeatMapGraphComponent: FC<Props> = ({
  gridData,
  expanded,
  description,
  useMeridianTime,
}) => {
  const adjustedData = useMemo(() => {
    if (!useMeridianTime) {
      const sundayData = gridData[6];
      const mondayToSaturdayData = gridData.slice(0, 6);
      return [sundayData, ...mondayToSaturdayData];
    }

    return gridData;
  }, [gridData, useMeridianTime]);

  const formatHour = (hour: number): string => {
    if (useMeridianTime) {
      return dayjs().hour(hour).format('HH');
    } else {
      return dayjs().hour(hour).format('ha').toLowerCase();
    }
  };

  const bestHoursByDay = useMemo(() => {
    return adjustedData.map((dayData) => {
      const hourValues = dayData
        .map((data, hour) => ({
          hour,
          value: data.value,
        }))
        .filter(({ value }) => value > 0.1);

      return hourValues
        .sort((a, b) => b.value - a.value)
        .slice(0, 2)
        .map((item) => item.hour);
    });
  }, [adjustedData]);

  return (
    <div
      className={clsx('tw-w-full tw-space-y-4', {
        'tw-text-md': expanded,
        'tw-text-sm': !expanded,
      })}
    >
      <div
        className={clsx(
          'tw-flex tw-items-center tw-justify-between tw-gap-4 tw-text-grayscale-120',
        )}
      >
        <div>{description}</div>
      </div>

      <div className="tw-flex tw-w-full tw-min-w-0 tw-flex-col">
        {days.map((day, dayIndex) => (
          <div key={day} className={clsx('tw-flex tw-w-full tw-min-w-0')}>
            <div
              className={clsx(
                'tw-flex tw-shrink-0 tw-items-center tw-justify-end tw-text-grayscale-100',
                {
                  'tw-w-12 tw-pr-2 tw-text-md': expanded,
                  'tw-w-6 tw-pr-1 tw-text-xs': !expanded,
                },
              )}
            >
              {day}
            </div>
            <div className="tw-flex tw-min-w-0 tw-flex-1">
              {hours.map((hour) => {
                const cellData = adjustedData[dayIndex][hour];
                const isBestHour = bestHoursByDay[dayIndex].includes(hour);
                const formattedHour = formatHour(hour);
                const tooltipContent = `${Math.round(
                  cellData.value * 100,
                )}% confidence, ${fullDays[dayIndex]} at ${formattedHour}`;

                return (
                  <Tooltip key={`${day}-${hour}`} content={tooltipContent}>
                    <div
                      className={clsx(
                        'tw-relative tw-aspect-square tw-min-w-0 tw-flex-1 tw-overflow-hidden tw-bg-grayscale-10 tw-bg-opacity-40',
                        {
                          'tw-m-px tw-rounded-sm': !expanded,
                          'tw-m-[2px] tw-rounded-md': expanded,
                        },
                      )}
                    >
                      <div
                        className="tw-absolute tw-inset-0 tw-bg-primary-100"
                        style={{
                          opacity: cellData.value,
                        }}
                      />
                      {isBestHour && (
                        <div className="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center">
                          <div
                            className={clsx(
                              'tw-rounded-full tw-bg-warning-100',
                              {
                                'tw-h-[6px] tw-w-[6px]': !expanded,
                                'tw-h-4 tw-w-4': expanded,
                              },
                            )}
                          ></div>
                        </div>
                      )}
                    </div>
                  </Tooltip>
                );
              })}
            </div>
          </div>
        ))}
        <div className="tw-flex tw-min-w-0">
          <div
            className={clsx('tw-shrink-0', {
              'tw-w-12': expanded,
              'tw-w-6': !expanded,
            })}
          />
          <div className="tw-flex tw-min-w-0 tw-flex-1">
            {hours.map((hour) => (
              <div
                key={hour}
                className={clsx(
                  'tw-min-w-0 tw-flex-1 tw-truncate tw-text-grayscale-100',
                  {
                    'tw-hidden': !expanded && hour % 2 !== 0,
                    'tw-mt-2 tw-text-center tw-text-md': expanded,
                    'tw-mt-1 tw-pl-[3px] tw-text-left tw-text-xs': !expanded,
                  },
                )}
              >
                {formatHour(hour)}
              </div>
            ))}
          </div>
        </div>

        <div className="tw-mt-4 tw-flex tw-flex-wrap tw-items-center tw-justify-end tw-gap-x-4 tw-gap-y-2">
          <div className="tw-flex tw-items-center tw-gap-1">
            <div className="tw-flex tw-items-center tw-overflow-hidden tw-rounded-sm">
              {ArrayUtils.generate(5)
                .slice(1)
                .map((shade) => (
                  <div
                    key={shade}
                    className={clsx('tw-bg-primary-100', {
                      'tw-h-3 tw-w-3': !expanded,
                      'tw-h-4 tw-w-4': expanded,
                    })}
                    style={{
                      opacity: shade / 5,
                    }}
                  ></div>
                ))}
            </div>
            <span
              className={clsx('tw-ml-1 tw-text-grayscale-120', {
                'tw-text-xs': !expanded,
                'tw-text-sm': expanded,
              })}
            >
              Activity + impressions
            </span>
          </div>

          <div className="tw-flex tw-items-center tw-gap-1">
            <div
              className={clsx('tw-rounded-full tw-bg-warning-100', {
                'tw-h-3 tw-w-3': !expanded,
                'tw-h-4 tw-w-4': expanded,
              })}
            ></div>
            <span
              className={clsx('tw-text-grayscale-120', {
                'tw-text-xs': !expanded,
                'tw-text-sm': expanded,
              })}
            >
              Best time to post
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeatMapGraphComponent;
