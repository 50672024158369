import { MetricProperties } from 'app/modules/insights/types/MetricProperty';
import { TYPE } from '@kontentino/kontentino-constants/Pages';
import dayjs from 'dayjs';

export const LI_METRICS_PROPERTIES: MetricProperties = {
  followers: {
    key: 'followers',
    title: 'pagePerformance.metricProperties.li.followers',
    queryParameter: 'followers',
  },
  engagement: {
    key: 'engagement',
    title: 'pagePerformance.metricProperties.common.engagement',
    queryParameter: 'engagement',
  },
  impressions: {
    key: 'impressions',
    title: 'pagePerformance.metricProperties.common.impressions',
    queryParameter: 'impressions',
  },
  followerGrowth: {
    key: 'followerGrowth',
    title: 'pagePerformance.metricProperties.li.followerGrowth',
    queryParameter: 'follower_growth',
  },
  bestTimeToPost: {
    key: 'bestTimeToPost',
    title: 'pagePerformance.metricProperties.li.bestTimeToPost',
    queryParameter: 'best_time_to_post',
  },
  bestTimeToPostAllTime: {
    key: 'bestTimeToPostAllTime',
    title: 'pagePerformance.metricProperties.li.bestTimeToPost',
    queryParameter: 'best_time_to_post',
    variableOverrides: {
      date: {
        start: dayjs().subtract(2, 'year'),
        end: dayjs(),
      },
    },
  },
};

export const IG_METRICS_PROPERTIES: MetricProperties = {
  reachedAudience: {
    key: 'reachedAudience',
    title: 'pagePerformance.metricProperties.ig.reachedAudience',
    queryParameter: 'reached_audience',
  },
  engagedAudience: {
    key: 'engagedAudience',
    title: 'pagePerformance.metricProperties.ig.engagedAudience',
    queryParameter: 'engaged_audience',
  },
  followerDemographics: {
    key: 'followerDemographics',
    title: 'pagePerformance.metricProperties.ig.followerDemographics',
    queryParameter: 'follower_demographics',
  },
  engagement: {
    key: 'engagement',
    title: 'Engagement rate',
    queryParameter: 'engagement_rate',
  },
  impressions: {
    key: 'impressions',
    title: 'Impressions',
    queryParameter: 'impressions',
  },
  reach: {
    key: 'reach',
    title: 'pagePerformance.metricProperties.ig.reach',
    queryParameter: 'reach',
  },
  emailContacts: {
    key: 'emailContacts',
    title: 'pagePerformance.metricProperties.ig.emailContacts',
    queryParameter: 'email_contact',
  },
  clicks: {
    key: 'clicks',
    title: 'pagePerformance.metricProperties.ig.clicks',
    queryParameter: 'clicks',
  },
  bestTimeToPost: {
    key: 'bestTimeToPost',
    title: 'pagePerformance.metricProperties.ig.bestTimeToPost',
    queryParameter: 'best_time_to_post',
  },
  bestTimeToPostAllTime: {
    key: 'bestTimeToPostAllTime',
    title: 'pagePerformance.metricProperties.ig.bestTimeToPost',
    queryParameter: 'best_time_to_post',
    variableOverrides: {
      date: {
        start: dayjs().subtract(2, 'year'),
        end: dayjs(),
      },
    },
  },
};

export const FB_METRICS_PROPERTIES: MetricProperties = {
  audience: {
    key: 'audience',
    title: 'pagePerformance.metricProperties.fb.audience',
    queryParameter: 'audience',
  },
  engagement: {
    key: 'engagement',
    title: 'pagePerformance.metricProperties.common.engagement',
    queryParameter: 'page_engagement_rate',
  },
  engaged_users: {
    key: 'engaged_users',
    title: 'pagePerformance.metricProperties.fb.engagedUsers',
    queryParameter: 'engaged_users',
  },
  post_engagement: {
    key: 'post_engagement',
    title: 'pagePerformance.metricProperties.fb.postEngagements',
    queryParameter: 'post_engagement',
  },
  page_consumption: {
    key: 'page_consumption',
    title: 'pagePerformance.metricProperties.fb.pageConsumption',
    queryParameter: 'page_consumption',
  },
  unique_page_consumption: {
    key: 'unique_page_consumption',
    title: 'pagePerformance.metricProperties.fb.uniquePageConsumption',
    queryParameter: 'unique_page_consumption',
  },
  page_impressions: {
    key: 'page_impressions',
    title: 'pagePerformance.metricProperties.common.impressions',
    queryParameter: 'page_impressions',
  },
  page_unique_impressions: {
    key: 'page_unique_impressions',
    title: 'pagePerformance.metricProperties.fb.uniqueImpressions',
    queryParameter: 'page_unique_impressions',
  },
  viral_frequency_distribution: {
    key: 'viral_frequency_distribution',
    title: 'pagePerformance.metricProperties.fb.viralFrequencyDistribution',
    queryParameter: 'viral_frequency_distribution',
  },
  paid_vs_organic_unique: {
    key: 'paid_vs_organic_unique',
    title: 'pagePerformance.metricProperties.fb.paidUniqueVsOrganicUnique',
    queryParameter: 'paid_vs_organic_unique',
  },
  viral_vs_nonviral: {
    key: 'viral_vs_nonviral',
    title: 'pagePerformance.metricProperties.fb.viralVsNonviral',
    queryParameter: 'viral_vs_nonviral',
  },
  viral_vs_nonviral_unique: {
    key: 'viral_vs_nonviral_unique',
    title: 'pagePerformance.metricProperties.fb.viralUniqueVsNonviralUnique',
    queryParameter: 'viral_vs_nonviral_unique',
  },
  paid_vs_organic: {
    key: 'paid_vs_organic',
    title: 'pagePerformance.metricProperties.fb.paidVsOrganic',
    queryParameter: 'paid_vs_organic',
  },
  bestTimeToPost: {
    key: 'bestTimeToPost',
    title: 'pagePerformance.metricProperties.fb.bestTimeToPost',
    queryParameter: 'best_time_to_post',
  },
  bestTimeToPostAllTime: {
    key: 'bestTimeToPostAllTime',
    title: 'pagePerformance.metricProperties.fb.bestTimeToPostAllTime',
    queryParameter: 'best_time_to_post',
    variableOverrides: {
      date: {
        start: dayjs().subtract(2, 'year'),
        end: dayjs(),
      },
    },
  },
} as const;

export const getMetricsProperty = (pageType: number, metricKey: string) => {
  function getMetricsForPageType() {
    switch (pageType) {
      case TYPE.FACEBOOK:
        return FB_METRICS_PROPERTIES;
      case TYPE.INSTAGRAM:
        return IG_METRICS_PROPERTIES;
      case TYPE.LINKEDIN:
        return LI_METRICS_PROPERTIES;
      default:
        return {} satisfies MetricProperties;
    }
  }

  return getMetricsForPageType()[metricKey];
};
