import {
  ErrorStepsGuideData,
  ErrorStepsGuideDataItem,
} from './errorStepsGuide/errorStepsGuide';
import { Icon } from '@kontentino/ui';
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons/faCheckCircle';
import { FC } from 'react';

type ErrorStepsGuideProps = {
  pageType: number;
};

const ErrorStepsGuide: FC<ErrorStepsGuideProps> = ({ pageType }) => {
  const guideData: ErrorStepsGuideDataItem | undefined =
    ErrorStepsGuideData[pageType];

  if (!guideData) {
    return null;
  }

  return (
    <div>
      <p className="tw-text-md tw-font-regular">
        Try these steps before retrying:
      </p>
      <ul className="hover:[&_a]:tw-text-underline tw-text-md tw-font-regular [&_a]:tw-font-medium [&_a]:tw-text-primary-100 hover:[&_a]:tw-cursor-pointer [&_strong]:tw-font-medium">
        {guideData.steps.map((step, index) => (
          <li key={index} className="tw-mt-4">
            <p className=" tw-flex tw-items-center tw-gap-2 ">
              <Icon icon={faCheckCircle} className="tw-text-success-100" />
              {step.text}
            </p>
            <p className="tw-ml-7">
              {step.subSteps && (
                <ul className="tw-list-disc tw-pl-4">
                  {step.subSteps.map((subStep, subIndex) => (
                    <li key={subIndex} className="tw-text-primary">
                      {subStep}
                    </li>
                  ))}
                </ul>
              )}
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ErrorStepsGuide;
