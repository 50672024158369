import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useUser } from 'modules/user/userSelector';
import { useAccount } from 'modules/account/accountSelector';
import { useAppLayoutState } from 'components/appLayout/appLayoutState';
import { getNavigationItems } from 'components/navigation/utils/libs';

const useNavigation = () => {
  const { pathname } = useLocation();
  const user = useUser();
  const account = useAccount();
  const { insightSelectedPageType } = useAppLayoutState();
  const items = getNavigationItems({
    account,
    user,
    insightSelectedPageType,
  });

  const activeMenuItem = useMemo(
    () =>
      items.find(
        (item) =>
          (item.routerUrl && pathname === item.routerUrl) ||
          pathname.includes(`${item.routerUrl}/`),
      ),
    [pathname, items],
  );

  return { user, account, items, activeMenuItem };
};

export default useNavigation;
