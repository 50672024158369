import { UserRole } from 'types/User';

export type ValidUserRole = Exclude<UserRole, ''>;

export const userRoles: Record<ValidUserRole, string> = {
  admin: 'Admin',
  manager: 'Manager',
  client: 'Client',
  translator: 'Translator/Copywriter',
  artist: 'Designer',
  media_agency: 'Media Agency',
};
