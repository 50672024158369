import PEOPLE from './assets/people.svg';
import { useActions } from 'app/hooks/useActions';
import { ActionButton } from 'app/components/actions/ActionButton';

export const PostsInternalCommunicationEmptyMessage = () => {
  const { userActions } = useActions('users');

  return (
    <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-6">
      <img src={PEOPLE} alt="People" />
      <p className="tw-text-md">{`You don't have any comments yet. Let's start collaborating!`}</p>
      <ActionButton
        action={userActions.inviteUser}
        data-name="post-communication_invite-user_button"
        data-cy="post-communication_invite-user_button"
        variant="primary"
        size="medium"
      >
        Invite a team member or client
      </ActionButton>
    </div>
  );
};
