import { useMemo, useState } from 'react';
import {
  ProfileSelectorFilterOptionKey,
  ProfileSelectorPageTypes,
} from 'app/components/profileSelector/types/profileSelectorFilter';
import { PageData, ProfileGroup } from 'types/Page';
import { SIDEBAR_DEFAULT_OPTIONS } from 'app/modules/posts/components/postsProfileSelector/constants';
import getPageTypeFilterOptions from 'app/components/profileSelector/utils/getPageTypeFilterOptions';
import getFilteredDataBySearchQuery from 'app/components/profileSelector/utils/getFilteredDataBySearchQuery';
import { useUserPermissions } from 'app/hooks/useUserPermissions';
import { UserPermission } from 'constants/userPermission';

type InputData = {
  profiles: PageData[];
  profileGroups: ProfileGroup[];
  selectedProfilesIds: number[];
  selectedProfileGroupsIds: number[];
};

const filterSelectedOptions = <T extends { id: number }>(
  options: T[],
  selectedIds: number[],
) => {
  return options.filter((option) => selectedIds.includes(option.id));
};

const usePostsSelectorFilter = (data: InputData) => {
  const initialState = useMemo(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const initialOpen = searchParams.get('profileSelectorOpen') === '1';
    const searchQuery = searchParams.get('profileSelectorSearchQuery') || '';

    return {
      initialOpen,
      searchQuery,
    };
  }, []);

  const [searchQueryValue, setSearchQueryValue] = useState<string>(
    initialState.searchQuery,
  );
  const [selectedFilterOption, setSelectedFilterOption] = useState<
    ProfileSelectorFilterOptionKey | ProfileSelectorPageTypes
  >(ProfileSelectorFilterOptionKey.All);
  const permissions = useUserPermissions();

  const sidebarPageTypeOptions = useMemo(() => {
    return getPageTypeFilterOptions(
      data.profiles.map((profile) => profile.type as ProfileSelectorPageTypes),
    );
  }, [data.profiles]);

  const sidebarFilterOptions = useMemo(() => {
    const allSelectedIds = [
      ...data.selectedProfilesIds,
      ...data.selectedProfileGroupsIds,
    ];

    return [
      ...SIDEBAR_DEFAULT_OPTIONS.filter((defaultFilterOption) => {
        if (allSelectedIds.length > 1) {
          return true;
        }

        if (
          defaultFilterOption.key ===
          ProfileSelectorFilterOptionKey.GlobalProfiles
        ) {
          return permissions.hasSome(UserPermission.ACCESS_GLOBAL_PAGES);
        }

        return (
          defaultFilterOption.key !== ProfileSelectorFilterOptionKey.Selected
        );
      }),
      ...sidebarPageTypeOptions,
    ];
  }, [
    data.selectedProfileGroupsIds,
    data.selectedProfilesIds,
    permissions,
    sidebarPageTypeOptions,
  ]);

  const filteredPagesBySelectedType = useMemo(() => {
    if (
      selectedFilterOption === ProfileSelectorFilterOptionKey.All ||
      selectedFilterOption === ProfileSelectorFilterOptionKey.Profiles
    ) {
      return data.profiles;
    }

    if (
      selectedFilterOption === ProfileSelectorFilterOptionKey.GlobalProfiles
    ) {
      return data.profiles.filter((profile) => profile.isGlobal);
    }

    if (selectedFilterOption === ProfileSelectorFilterOptionKey.Selected) {
      return filterSelectedOptions(data.profiles, data.selectedProfilesIds);
    }

    if (!Number.isNaN(selectedFilterOption)) {
      return data.profiles.filter(
        (profile) => profile.type === selectedFilterOption,
      );
    }

    return [];
  }, [selectedFilterOption, data.profiles, data.selectedProfilesIds]);

  const filteredProfiles = useMemo(() => {
    return getFilteredDataBySearchQuery<PageData>(
      searchQueryValue,
      filteredPagesBySelectedType,
    );
  }, [filteredPagesBySelectedType, searchQueryValue]);

  const filteredProfilesGroups = useMemo(() => {
    const profileGroupOptions =
      selectedFilterOption === ProfileSelectorFilterOptionKey.Selected
        ? filterSelectedOptions(
            data.profileGroups,
            data.selectedProfileGroupsIds,
          )
        : data.profileGroups;

    return getFilteredDataBySearchQuery<ProfileGroup>(
      searchQueryValue,
      profileGroupOptions,
    );
  }, [
    data.profileGroups,
    data.selectedProfileGroupsIds,
    searchQueryValue,
    selectedFilterOption,
  ]);

  const getFilteredOptions = () => {
    const profileGroupsOptions = filteredProfilesGroups.map((profileGroup) => ({
      ...profileGroup,
      logoSrc: profileGroup.logo?.src,
      isGroup: true,
    }));
    const profilesOptions = filteredProfiles.map((profile) => ({
      ...profile,
      logoSrc: profile.logo?.src,
      socialMediaType: profile.type,
      isDemo: profile.isDemo,
      isConnected: !!profile.isConnected,
      isGlobal: profile.isGlobal,
    }));

    switch (selectedFilterOption) {
      case ProfileSelectorFilterOptionKey.Selected:
      case ProfileSelectorFilterOptionKey.All:
        return [...profileGroupsOptions, ...profilesOptions];
      case ProfileSelectorFilterOptionKey.ProfileGroups:
        return profileGroupsOptions;
      case ProfileSelectorFilterOptionKey.Profiles:
      default:
        return profilesOptions;
    }
  };

  return {
    sidebarFilterOptions,
    searchQuery: searchQueryValue,
    selectedFilter: selectedFilterOption,
    setSearchQuery: setSearchQueryValue,
    selectFilter: setSelectedFilterOption,
    filteredOptions: getFilteredOptions(),
    initialOpen: initialState.initialOpen,
  };
};

export default usePostsSelectorFilter;
