import { TYPE } from '@kontentino/kontentino-constants/Pages';
import SelectNewProfileType from 'components/modals/newSocialMediaProfile/SelectNewProfileType';
import Modal from 'components/shared/Modal';
import React, { FC, useEffect } from 'react';
import { ModalRouteProps } from 'context/ModalRouter';
import { ActivityIndicator } from '@kontentino/ui';
import { INSIGHTS_FB_AND_IG_LOGIN_SCOPES } from 'app/modules/insights/constants/socialLoginScopes';
import AnalyticsApi from 'api/analytics';
import { useApolloClient } from '@apollo/client';
import { QueryKey } from 'components/insights/constants';
import { useMutation, useQueryClient } from 'react-query';
import SocialLoginApi from 'app/modules/socialLogin/api';
import { SocialLoginPage } from 'types/SocialLogin';
import { ApiClientError } from 'api/client';
import { useSocialLoginContext } from 'app/modules/socialLogin/context';

type Props = ModalRouteProps & {};

const availableTypes = [TYPE.FACEBOOK, TYPE.INSTAGRAM, TYPE.LINKEDIN];

export const InsightsAddNewProfileModal: FC<Props> = ({ modalManager }) => {
  const socialLogin = useSocialLoginContext();
  const apolloClient = useApolloClient();
  const queryClient = useQueryClient();
  const { mutate: mutateLoadPages, isLoading: isLoadPagesLoading } =
    useMutation(AnalyticsApi.loadPages, {
      onSuccess: () => {
        apolloClient.resetStore();
        queryClient.resetQueries(QueryKey.initialization());
        modalManager.close();
      },
    });
  const { mutate: mutatePagesToSocialLogin, isLoading: isAddPagesLoading } =
    useMutation<
      any,
      ApiClientError,
      {
        pageType: number;
        data: {
          pages: SocialLoginPage[];
        };
      }
    >(
      (variables) =>
        SocialLoginApi.addPagesToSocialLogin(
          variables.pageType,
          variables.data,
        ),
      {
        onSuccess: () => {
          mutateLoadPages();
        },
      },
    );

  useEffect(() => {
    return () => {
      socialLogin.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (socialLogin.isSuccess && socialLogin.pageType) {
      mutatePagesToSocialLogin({
        pageType: socialLogin.pageType,
        data: {
          pages: socialLogin.authorization.data,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socialLogin.isSuccess]);

  return (
    <Modal
      modalManager={modalManager}
      size="responsive"
      dataName={{
        wrapper: 'insights_add-profile-modal',
        closeButton: 'insights_add-profile-modal_close-icon',
      }}
      classNames={{ modal: '!tw-max-w-[440px]' }}
    >
      <Modal.Header title="Add a profile" />
      <Modal.Content className="tw-mb-6">
        {!socialLogin.isLoading &&
          !isLoadPagesLoading &&
          !isAddPagesLoading && (
            <SelectNewProfileType
              socialMediaTypes={availableTypes}
              onSelectSocialMediaType={(pageType) => {
                socialLogin.authorize({
                  pageType,
                  scopes: (
                    [TYPE.INSTAGRAM, TYPE.FACEBOOK] as number[]
                  ).includes(pageType)
                    ? INSIGHTS_FB_AND_IG_LOGIN_SCOPES
                    : undefined,
                });
              }}
              dataNamePrefix={{
                socialTypeOption: 'insights_add-profile-modal_',
              }}
            />
          )}
        {(socialLogin.isLoading || isLoadPagesLoading || isAddPagesLoading) && (
          <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-py-6">
            <ActivityIndicator className="tw-h-12 tw-w-12" />
            <p className="tw-mt-6 tw-text-md">Authorizing...</p>
          </div>
        )}
      </Modal.Content>
    </Modal>
  );
};
