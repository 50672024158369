import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import store from 'store';
import { logRequest, logResponse } from 'utils/client';
import { getExtendedTimeout, getUserMessage } from 'utils/request';
import {
  rejectWrongResponse,
  shouldRejectWrongResponse,
} from 'api/client/middleware.utils';
import config from 'config';
import { ApiClientError } from 'api/client/index';
import DataLayer from 'app/utils/dataLayer';
import Logger from 'utils/logger';

export function requestLogger(request: AxiosRequestConfig) {
  const info = `${new Date().toLocaleTimeString()} | Request: ${request.method?.toUpperCase()} | ${
    request.url
  } | Sent data:`;

  let serializedData;

  if (request.data instanceof FormData) {
    serializedData = Object.fromEntries(Array.from(request.data));
  }

  logRequest(info, serializedData ?? request.data);

  return request;
}

export function responseLogger(response: AxiosResponse) {
  const info = `${new Date().toLocaleTimeString()} | Response: ${
    response.status
  } | ${response.config.url}| Response data:`;

  logResponse(info, response.data);

  return response;
}

// If sent formData contains files extend timeout of request
export function timeoutExtender(request: AxiosRequestConfig) {
  if (request.data instanceof FormData) {
    for (const item of Array.from(request.data)) {
      if (item[1] instanceof File) {
        if (
          typeof request.timeout === 'number' &&
          request.timeout > getExtendedTimeout()
        ) {
          break;
        }

        request.timeout = getExtendedTimeout();
        break;
      }
    }
  }

  return request;
}

export function responseChecker(response: AxiosResponse) {
  if (shouldRejectWrongResponse(response)) {
    DataLayer.push({
      event: DataLayer.events.REJECTED_REQUEST_RESPONSE,
      response,
    });
    Logger.error('Rejected response', response);

    return rejectWrongResponse(response);
  }

  if (response.data?.data && response.data?.pagination) {
    return response.data as ApiResponse;
  }

  return response.data?.data !== undefined ? response.data.data : response.data;
}

export function rejectExtender(axiosError: AxiosError) {
  if (axiosError.response && shouldRejectWrongResponse(axiosError.response)) {
    DataLayer.push({
      event: DataLayer.events.REJECTED_REQUEST_RESPONSE,
      error: axiosError,
    });
    Logger.error('Rejected response', axiosError);
    return rejectWrongResponse(axiosError.response);
  }

  if (axiosError.code === 'ECONNABORTED') {
    DataLayer.push({
      event: DataLayer.events.TIMEOUT_REQUEST_RESPONSE,
      error: axiosError,
    });
  }

  return Promise.reject(
    new ApiClientError({
      userMessage: getUserMessage(axiosError.response),
      axiosError,
      name: axiosError.name,
      message: axiosError.message,
      stack: axiosError.stack,
    }),
  );
}

export function authJoiner(request: AxiosRequestConfig) {
  const authToken = store.getState().app.authToken;

  function getHeaders() {
    if (
      [
        config.ANALYTICS_ENDPOINT,
        config.AI_API_ENDPOINT,
        config.SOCIAL_LOGIN_SERVICE_ENDPOINT,
      ].some((baseUrl) => baseUrl === request.baseURL) &&
      !request.headers['Authorization']
    ) {
      return {
        ...request.headers,
        Authorization: `Bearer ${authToken}`,
      };
    }

    return {
      ...request.headers,
      'x-http-jwt-token': authToken,
    };
  }

  request.headers = getHeaders();

  return request;
}
