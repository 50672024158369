import { STATUS } from '@kontentino/kontentino-constants/Posts';
import { UserPermission } from 'constants/userPermission';
import { POST_STATUS_BY_USER_PERMISSION } from 'app/modules/posts/constants';
import { TYPE } from '@kontentino/kontentino-constants/Pages';

export const SORTED_STATUSES = [
  STATUS.DRAFT,
  STATUS.COMPLETED,
  STATUS.WAITING_FOR,
  STATUS.DONE,
  STATUS.WAITING_FOR_INTERNAL_APPROVAL,
  STATUS.REJECTED,
  STATUS.INTERNAL_APPROVED,
  STATUS.AT_CLIENT,
  STATUS.REJECTED_BY_CLIENT,
  STATUS.APPROVED,
  STATUS.IS_SCHEDULING,
  STATUS.SCHEDULED,
  STATUS.POSTED,
  STATUS.EXPORTED,
  STATUS.EXPORT_FAILED,
] as number[];

export const createGetAvailableStatuses = (
  permissionsKeys: UserPermission[],
): ((pageTypes: number[]) => number[]) => {
  const statuses = getStatusesByUserPermissions(permissionsKeys);

  return (pageTypes: number[]) => {
    if (!pageTypes.includes(TYPE.FACEBOOK)) {
      return statuses.filter((status) => status !== STATUS.EXPORTED);
    }

    return statuses;
  };
};

export const getStatusesByUserPermissions = (
  permissionsKeys: UserPermission[],
): number[] => {
  if (permissionsKeys.includes(UserPermission.POST_LIST_ALL)) {
    return Object.values(POST_STATUS_BY_USER_PERMISSION);
  }

  const statusesByPermissions = permissionsKeys
    .filter((permissionKey) => permissionKey in POST_STATUS_BY_USER_PERMISSION)
    .map((permissionKey) => POST_STATUS_BY_USER_PERMISSION[permissionKey]);

  return statusesByPermissions.sort((statusA, statusB) => {
    const indexA = SORTED_STATUSES.indexOf(statusA);
    const indexB = SORTED_STATUSES.indexOf(statusB);
    return indexA - indexB;
  });
};

export const NOT_ALLOWED_EDIT_STATUSES = [
  STATUS.EXPORT_FAILED,
  STATUS.IS_SCHEDULING,
  STATUS.FAILED,
] as number[];

export const NOT_DISPLAYED_KANBAN_STATUSES = [
  STATUS.EXPORT_FAILED,
  STATUS.IS_SCHEDULING,
] as number[];
