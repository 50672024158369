import React, { FC } from 'react';
import GenericGraph from '../GenericGraph';
import {
  GraphData,
  GraphVariant,
  MetricCardData,
  MetricTableData,
} from 'app/modules/insights/types/PagePerformance';
import MetricTable from '../MetricTable';
import { FilterState } from 'app/modules/insights/hooks/useFilterState';
import PageMetricCardSubmetricsTabs from 'app/modules/insights/modules/pagePerformance/components/pageMetricCard/PageMetricCardSubmetricsTabs';
import { getGraphLegendLabels } from 'app/modules/insights/modules/pagePerformance/utils/getGraphLabels';
import { usePageMetricCardContentContext } from 'app/modules/insights/modules/pagePerformance/components/pageMetricCard/PageMetricCardContentProvider';
import AggregatedMetricsValues from 'app/modules/insights/modules/pagePerformance/components/aggregatedMetricValues/AggregatedMetricsValues';

type Props = {
  metricCardData: MetricCardData;
  isCompared?: boolean;
  isCombinedMetric: boolean;
  metricTableData?: MetricTableData;
  title: string;
  combinedMetricComparedData: GraphData[];
  filterState: Pick<
    FilterState,
    'startDate' | 'endDate' | 'comparedEndDate' | 'comparedStartDate'
  >;
  dataName?: {
    summaryMetrics?: string;
    metricTable?: string;
  };
  expanded: boolean;
  onToggleExpandClick: () => void;
  chartWidth?: number | string;
  chartHeight?: number | string;
  showTabs?: boolean;
};

const PageMetricCardContentGraph: FC<Props> = ({
  isCompared,
  dataName,
  title,
  combinedMetricComparedData,
  filterState,
  expanded,
  onToggleExpandClick,
  chartWidth,
  chartHeight,
  showTabs = true,
}) => {
  const { metricCardData, activeTabMetric, useMeridianTime } =
    usePageMetricCardContentContext();

  function getCurrentGraphVariant(): GraphVariant {
    if (
      ((metricCardData.isCombinedMetric && isCompared) || expanded) &&
      metricCardData.metricCardProperties.alternativeGraphType
    ) {
      return metricCardData.metricCardProperties.alternativeGraphType;
    }

    return metricCardData.metricCardProperties.graphType;
  }

  return (
    <div className="tw-flex tw-flex-col tw-pt-3">
      {!metricCardData.metricCardProperties.withoutGeneralLabels && (
        <div className="tw-mb-6">
          <AggregatedMetricsValues
            expanded={expanded}
            metricCardData={metricCardData}
            isCompared={isCompared}
            dataName={{
              summaryMetrics: dataName?.summaryMetrics,
            }}
          />
        </div>
      )}
      <div>
        {metricCardData.metricCardProperties.graphType === 'table' &&
        !expanded &&
        metricCardData.metricTableData &&
        metricCardData.metricTableData['default'] ? (
          <MetricTable
            metrics={metricCardData.metricTableData['default']}
            isCompared={isCompared}
            dataName={{
              table: dataName?.metricTable,
            }}
          />
        ) : (
          <>
            {showTabs && (
              <div className="tw-mb-4">
                <PageMetricCardSubmetricsTabs
                  expanded={expanded}
                  onToggleExpandClick={onToggleExpandClick}
                />
              </div>
            )}
            {metricCardData.metricGraphData
              .filter((metric) => metric.key === activeTabMetric)
              .map((metric, index) => (
                <GenericGraph
                  height={chartHeight}
                  width={chartWidth}
                  key={metric.key}
                  filterState={filterState}
                  expanded={expanded}
                  title={title}
                  variant={getCurrentGraphVariant()}
                  data={metric.graphValues.data}
                  gridData={metric.graphValues.gridData}
                  isCompared={isCompared}
                  legendLabels={getGraphLegendLabels({
                    ...filterState,
                    metricGraphDataItem: metric,
                    generalDataItem:
                      metricCardData.generalData.find(
                        (value) => value.metricKey === metric.key,
                      ) ?? metricCardData.generalData[0],
                  })}
                  description={metricCardData.metricCardProperties.description}
                  isCombinedMetric={metricCardData.isCombinedMetric}
                  combinedMetricComparedData={combinedMetricComparedData}
                  useMeridianTime={useMeridianTime}
                />
              ))}
          </>
        )}
      </div>
    </div>
  );
};

export default PageMetricCardContentGraph;
