import React, { FC, useEffect } from 'react';
import Modal from 'components/shared/Modal';
import AutomaticForm from 'components/modals/newSocialMediaProfile/automaticForm/Form';
import { ModalRouteProps } from 'context/ModalRouter';
import i18n from 'i18n';
import { NumberParam, useQueryParams } from 'use-query-params';
import { ActivityIndicator } from '@kontentino/ui';
import useAddNewProfileAutomatically from 'app/modules/posts/hooks/useAddNewProfileAutomatically';
import { useSocialLoginContext } from 'app/modules/socialLogin/context';
import { usePostsContainer } from 'components/posts/context';

type Props = ModalRouteProps & {
  onProfileAdded?: (profileId: number) => void;
};

export const AssociateProfileModal: FC<Props> = ({
  modalManager,
  modalHistory,
  onProfileAdded,
}) => {
  const socialLogin = useSocialLoginContext();
  const [query] = useQueryParams({
    type: NumberParam,
    id: NumberParam,
  });
  const automaticPageAdded = useAddNewProfileAutomatically({
    onProfileAdded,
  });

  useEffect(() => {
    return () => {
      socialLogin.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      modalManager={modalManager}
      size="sm"
      dataName={{
        wrapper: 'associate-social-media-profile-modal',
      }}
    >
      <Modal.Header title={i18n.modal.associateProfileTitle} />
      <Modal.Content className="tw-p-0">
        {query.type && (
          <AutomaticForm
            {...socialLogin}
            pageType={query.type}
            onPageAdded={automaticPageAdded.onPageAdded}
            onGoBack={modalHistory.closeLast}
          />
        )}
        {!query.type && (
          <div className="tw-flex tw-items-center tw-justify-center tw-p-10">
            <ActivityIndicator className="tw-h-7 tw-w-7" />
          </div>
        )}
      </Modal.Content>
    </Modal>
  );
};

const PostsAssociateProfileModal: FC<ModalRouteProps> = (props) => {
  const postsContainer = usePostsContainer();

  return (
    <AssociateProfileModal
      {...props}
      onProfileAdded={(id) =>
        postsContainer.changeFilter({
          key: 'selectedProfiles',
          value: [id],
        })
      }
    />
  );
};

export default PostsAssociateProfileModal;
