import { TYPE as PAGE_TYPE } from '@kontentino/kontentino-constants/Pages';
import { PseudoPostType } from 'constants/post';
import { faCircleO } from '@fortawesome/pro-regular-svg-icons/faCircleO';
import { faClapperboardPlay } from '@fortawesome/pro-regular-svg-icons/faClapperboardPlay';
import { faGalleryThumbnails } from '@fortawesome/pro-regular-svg-icons/faGalleryThumbnails';
import { faImage } from '@fortawesome/pro-regular-svg-icons/faImage';
import { faImages } from '@fortawesome/pro-regular-svg-icons/faImages';
import { faLink } from '@fortawesome/pro-regular-svg-icons/faLink';
import { faMusic } from '@fortawesome/pro-regular-svg-icons/faMusic';
import { faPercent } from '@fortawesome/pro-regular-svg-icons/faPercent';
import { faText } from '@fortawesome/pro-regular-svg-icons/faText';
import { faVideo } from '@fortawesome/pro-regular-svg-icons/faVideo';
import {
  PostSubtype,
  PostType,
  SUBTYPE as POST_SUBTYPE,
  TYPE as POST_TYPE,
} from '@kontentino/kontentino-constants/Posts';
import { Attachments } from 'types/Attachments';
import { PostComposerFormState } from '../modules/post/components/composer/utils/postComposerFormState';
import { ReactNode } from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';

export type PseudoPostTypeOption = {
  key: PseudoPostType;
  icon: IconDefinition;
  label: ReactNode;
  helpText?: string;
  getTypeAndSubtype: (postData: Pick<PostComposerFormState, 'attachments'>) => {
    type: PostType;
    subtype: PostSubtype;
  };
};

function getPhotoOrGalleryTypeAndSubtype(
  attachments: Attachments,
  isInstagram?: boolean,
) {
  return {
    type: attachments.cards.length > 1 ? POST_TYPE.GALLERY : POST_TYPE.PHOTO,
    subtype:
      attachments.cards.length > 1 && isInstagram
        ? POST_SUBTYPE.INSTAGRAM_SLIDESHOW
        : POST_SUBTYPE.DEFAULT,
  };
}

export const PSEUDO_POST_TYPE_OPTIONS_BY_PAGE_TYPE: Record<
  number,
  PseudoPostTypeOption[]
> = {
  [PAGE_TYPE.FACEBOOK_AD]: [
    {
      key: PseudoPostType.Ad,
      icon: faClapperboardPlay,
      label: 'Ad',
      getTypeAndSubtype: () => ({
        type: POST_TYPE.LINK,
        subtype: POST_SUBTYPE.DEFAULT,
      }),
    },
    {
      key: PseudoPostType.Photo,
      icon: faImage,
      label: 'Photo',
      getTypeAndSubtype: () => ({
        type: POST_TYPE.PHOTO,
        subtype: POST_SUBTYPE.DEFAULT,
      }),
    },
    {
      key: PseudoPostType.Video,
      icon: faVideo,
      label: 'Video',
      getTypeAndSubtype: () => ({
        type: POST_TYPE.VIDEO,
        subtype: POST_SUBTYPE.DEFAULT,
      }),
    },
  ],
  [PAGE_TYPE.FACEBOOK]: [
    {
      key: PseudoPostType.Status,
      icon: faText,
      label: 'Status',
      getTypeAndSubtype: () => ({
        type: POST_TYPE.COMMENT,
        subtype: POST_SUBTYPE.DEFAULT,
      }),
    },
    {
      key: PseudoPostType.PhotoGallery,
      icon: faImages,
      label: 'Photo/Gallery',
      getTypeAndSubtype: (postData) =>
        getPhotoOrGalleryTypeAndSubtype(postData.attachments),
    },
    {
      key: PseudoPostType.Video,
      icon: faVideo,
      label: 'Video',
      getTypeAndSubtype: () => ({
        type: POST_TYPE.VIDEO,
        subtype: POST_SUBTYPE.DEFAULT,
      }),
    },
    {
      key: PseudoPostType.Link,
      icon: faLink,
      label: 'Link',
      getTypeAndSubtype: () => ({
        type: POST_TYPE.LINK,
        subtype: POST_SUBTYPE.DEFAULT,
      }),
    },
    {
      key: PseudoPostType.Reel,
      label: 'Reel',
      icon: faClapperboardPlay,
      getTypeAndSubtype: () => ({
        type: POST_TYPE.VIDEO,
        subtype: POST_SUBTYPE.REEL,
      }),
    },
    {
      key: PseudoPostType.Story,
      label: 'Story',
      icon: faCircleO,
      getTypeAndSubtype: () => ({
        type: POST_TYPE.GALLERY,
        subtype: POST_SUBTYPE.INSTAGRAM_STORY,
      }),
    },
    {
      key: PseudoPostType.Carousel,
      icon: faGalleryThumbnails,
      label: 'Carousel',
      getTypeAndSubtype: () => ({
        type: POST_TYPE.LINK,
        subtype: POST_SUBTYPE.CAROUSEL,
      }),
    },
  ],
  [PAGE_TYPE.INSTAGRAM]: [
    {
      key: PseudoPostType.PhotoGallery,
      label: 'Photo/Carousel',
      icon: faImages,
      getTypeAndSubtype: (postData) =>
        getPhotoOrGalleryTypeAndSubtype(postData.attachments, true),
    },
    {
      key: PseudoPostType.Reel,
      label: 'Reel',
      icon: faClapperboardPlay,
      getTypeAndSubtype: () => ({
        type: POST_TYPE.VIDEO,
        subtype: POST_SUBTYPE.REEL,
      }),
    },
    {
      key: PseudoPostType.Story,
      label: 'Story',
      icon: faCircleO,
      getTypeAndSubtype: () => ({
        type: POST_TYPE.GALLERY,
        subtype: POST_SUBTYPE.INSTAGRAM_STORY,
      }),
    },
  ],
  [PAGE_TYPE.TWITTER]: [
    {
      key: PseudoPostType.Status,
      icon: faText,
      label: 'Status',
      getTypeAndSubtype: () => ({
        type: POST_TYPE.COMMENT,
        subtype: POST_SUBTYPE.DEFAULT,
      }),
    },
    {
      key: PseudoPostType.PhotoGallery,
      icon: faImages,
      label: 'Photo/Gallery',
      getTypeAndSubtype: (postData) =>
        getPhotoOrGalleryTypeAndSubtype(postData.attachments),
    },
    {
      key: PseudoPostType.Video,
      icon: faVideo,
      label: 'Video',
      getTypeAndSubtype: () => ({
        type: POST_TYPE.VIDEO,
        subtype: POST_SUBTYPE.DEFAULT,
      }),
    },
    {
      key: PseudoPostType.Link,
      icon: faLink,
      label: 'Link',
      getTypeAndSubtype: () => ({
        type: POST_TYPE.LINK,
        subtype: POST_SUBTYPE.DEFAULT,
      }),
    },
  ],
  [PAGE_TYPE.LINKEDIN]: [
    {
      key: PseudoPostType.Status,
      icon: faText,
      label: 'Status',
      getTypeAndSubtype: () => ({
        type: POST_TYPE.COMMENT,
        subtype: POST_SUBTYPE.DEFAULT,
      }),
    },
    {
      key: PseudoPostType.PhotoGallery,
      icon: faImages,
      label: 'Photo/Gallery',
      getTypeAndSubtype: (postData) =>
        getPhotoOrGalleryTypeAndSubtype(postData.attachments),
    },
    {
      key: PseudoPostType.Video,
      icon: faVideo,
      label: 'Video',
      getTypeAndSubtype: () => ({
        type: POST_TYPE.VIDEO,
        subtype: POST_SUBTYPE.DEFAULT,
      }),
    },

    {
      key: PseudoPostType.Link,
      icon: faLink,
      label: 'Link',
      getTypeAndSubtype: () => ({
        type: POST_TYPE.LINK,
        subtype: POST_SUBTYPE.DEFAULT,
      }),
    },
  ],
  [PAGE_TYPE.PINTEREST]: [
    {
      key: PseudoPostType.Photo,
      icon: faImage,
      label: 'Photo',
      getTypeAndSubtype: () => ({
        type: POST_TYPE.PHOTO,
        subtype: POST_SUBTYPE.DEFAULT,
      }),
    },
  ],
  [PAGE_TYPE.GOOGLE_MY_BUSINESS]: [
    {
      key: PseudoPostType.Status,
      icon: faText,
      label: 'Update',
      helpText: 'Share announcements and the latest business updates.',
      getTypeAndSubtype: () => ({
        type: POST_TYPE.LINK,
        subtype: POST_SUBTYPE.DEFAULT,
      }),
    },
    {
      key: PseudoPostType.Event,
      icon: faMusic,
      label: 'Event',
      helpText: 'Promote your upcoming online and live events.',
      getTypeAndSubtype: () => ({
        type: POST_TYPE.LINK,
        subtype: POST_SUBTYPE.GMB_EVENT_POST,
      }),
    },
    {
      key: PseudoPostType.Offer,
      icon: faPercent,
      label: 'Offer',
      helpText: 'Showcase promotional sales, exclusive offers, and coupons.',
      getTypeAndSubtype: () => ({
        type: POST_TYPE.LINK,
        subtype: POST_SUBTYPE.GMB_OFFER_POST,
      }),
    },
  ],
  [PAGE_TYPE.TIKTOK]: [
    {
      key: PseudoPostType.Video,
      icon: faVideo,
      label: 'Vertical video',
      getTypeAndSubtype: () => ({
        type: POST_TYPE.VIDEO,
        subtype: POST_SUBTYPE.DEFAULT,
      }),
    },
    {
      key: PseudoPostType.PhotoGallery,
      label: 'Photo/Gallery',
      icon: faImages,
      getTypeAndSubtype: (postData) =>
        getPhotoOrGalleryTypeAndSubtype(postData.attachments),
    },
  ],
  [PAGE_TYPE.YOUTUBE]: [
    {
      key: PseudoPostType.Video,
      icon: faVideo,
      label: 'Vertical video',
      getTypeAndSubtype: () => ({
        type: POST_TYPE.VIDEO,
        subtype: POST_SUBTYPE.DEFAULT,
      }),
    },
  ],
  [PAGE_TYPE.THREADS]: [
    {
      key: PseudoPostType.Status,
      icon: faText,
      label: 'Text',
      getTypeAndSubtype: () => ({
        type: POST_TYPE.COMMENT,
        subtype: POST_SUBTYPE.DEFAULT,
      }),
    },
    {
      key: PseudoPostType.PhotoGallery,
      icon: faImages,
      label: 'Photo/Gallery',
      getTypeAndSubtype: (postData) =>
        getPhotoOrGalleryTypeAndSubtype(postData.attachments),
    },
    {
      key: PseudoPostType.Video,
      icon: faVideo,
      label: 'Video',
      getTypeAndSubtype: () => ({
        type: POST_TYPE.VIDEO,
        subtype: POST_SUBTYPE.DEFAULT,
      }),
    },
  ],
};
