import { TYPE } from '@kontentino/kontentino-constants/Pages';
import React from 'react';

export interface ErrorStepsGuideDataItem {
  steps: Array<{
    text: React.ReactNode;
    subSteps?: React.ReactNode[];
  }>;
}

const guideLinks = {
  [TYPE.FACEBOOK]:
    'https://help.kontentino.com/en/articles/10625143-how-to-check-facebook-account-permissions-for-connecting-to-a-kontentino',
  [TYPE.INSTAGRAM]:
    'https://help.kontentino.com/en/articles/10625136-how-to-check-instagram-account-accessibility-for-connecting-to-a-kontentino',
  [TYPE.LINKEDIN]:
    'https://help.kontentino.com/en/articles/10625084-how-to-check-linkedin-account-permissions-for-connecting-to-a-kontentino',
  [TYPE.PINTEREST]:
    'https://help.kontentino.com/en/articles/10625033-how-to-check-pinterest-account-permissions-for-connecting-to-a-kontentino',
  [TYPE.TWITTER]:
    'https://help.kontentino.com/en/articles/10625004-how-to-check-x-twitter-account-permissions-for-connecting-to-kontentino',
  [TYPE.THREADS]:
    'https://help.kontentino.com/en/articles/10616796-how-to-check-threads-account-permissions-for-connecting-to-a-kontentino',
  [TYPE.GOOGLE_MY_BUSINESS]:
    'https://help.kontentino.com/en/articles/10616073-how-to-check-google-account-permissions-for-connecting-to-a-kontentino',
  [TYPE.TIKTOK]:
    'https://help.kontentino.com/en/articles/10727195-how-to-check-tiktok-account-permissions-for-connecting-to-a-kontentino',
};

export const ErrorStepsGuideData: Partial<
  Record<number, ErrorStepsGuideDataItem>
> = {
  [TYPE.FACEBOOK]: {
    steps: [
      {
        text: (
          <p>
            Check your <strong>role</strong> on the Facebook Page
          </p>
        ),
        subSteps: [
          <p>
            Go to the <strong>Settings</strong>
          </p>,
          <p>
            Select <strong>Page setup</strong> (left sidebar) →{' '}
            <strong>Page access</strong>
          </p>,
          <p>
            Check if your account has <strong>Admin access</strong>. If you
            don’t see "Admin," you’ll need someone with Admin access to add you
          </p>,
        ],
      },
      {
        text: (
          <p>
            For <strong>step-by-step guide</strong>, click this{' '}
            <a
              href={guideLinks[TYPE.FACEBOOK]}
              target="_blank"
              rel="noreferrer"
            >
              link
            </a>
          </p>
        ),
      },
    ],
  },
  [TYPE.INSTAGRAM]: {
    steps: [
      {
        text: <p>Check your Instagram account type</p>,
        subSteps: [
          <p>
            Go to the <strong>Menu → Settings</strong>
          </p>,
          <p>
            Select <strong>Account type and tools</strong>
          </p>,
          <p>
            Look for <strong>Switch to professional account</strong>
          </p>,
          <p>
            <strong>If you see this option</strong>, your account is still{' '}
            <strong>Personal</strong> - you need to switch to{' '}
            <strong>Business</strong>.
          </p>,
        ],
      },
      {
        text: (
          <p>
            For <strong>step-by-step guide</strong>, click this{' '}
            <a
              href={guideLinks[TYPE.INSTAGRAM]}
              target="_blank"
              rel="noreferrer"
            >
              link
            </a>
          </p>
        ),
      },
    ],
  },
  [TYPE.LINKEDIN]: {
    steps: [
      {
        text: <p>Check your role on the LinkedIn Page</p>,
        subSteps: [
          <p>
            Click on your <strong>profile picture (top right corner)</strong>{' '}
            and select <strong>Company Page</strong>
          </p>,
          <p>
            In the Admin tools section, click <strong>Manage admins</strong>{' '}
          </p>,
          <p>
            Look for your <strong>LinkedIn profile</strong> in the list of
            admins{' '}
          </p>,
        ],
      },
      {
        text: (
          <p>
            For <strong>step-by-step guide</strong>, click this{' '}
            <a
              href={guideLinks[TYPE.LINKEDIN]}
              target="_blank"
              rel="noreferrer"
            >
              link
            </a>
          </p>
        ),
      },
    ],
  },
  [TYPE.PINTEREST]: {
    steps: [
      {
        text: <p>Check if your Pinterest Account is a Business Account</p>,
        subSteps: [
          <p>
            Click your <strong>profile picture</strong> in the top-right corner{' '}
          </p>,
          <p>
            Select <strong>Settings → Account Management</strong>{' '}
          </p>,
          <p>
            Look under <strong>Account changes</strong> → if you see an option
            to <strong>"Switch to Business Account"</strong>, it means your
            account is still <strong>Personal</strong>—switch to{' '}
            <strong>Business</strong> to proceed.
          </p>,
        ],
      },
      {
        text: (
          <p>
            For <strong>step-by-step guide</strong>, click this{' '}
            <a
              href={guideLinks[TYPE.PINTEREST]}
              target="_blank"
              rel="noreferrer"
            >
              link
            </a>
          </p>
        ),
      },
    ],
  },
  [TYPE.TWITTER]: {
    steps: [
      {
        text: <p>Check your Account Permissions</p>,
        subSteps: [
          <p>
            Click on your <strong>profile picture</strong> (top-left corner) and
            go to <strong>Settings and privacy</strong>
          </p>,
          <p>
            Navigate to{' '}
            <strong>Security and account access → Apps and sessions</strong>
          </p>,
          <p>
            Under <strong>Connected apps</strong>, check if you have already{' '}
            <strong>granted permissions</strong> to third-party apps.
          </p>,
        ],
      },
      {
        text: (
          <p>
            For <strong>step-by-step guide</strong>, click this{' '}
            <a href={guideLinks[TYPE.TWITTER]} target="_blank" rel="noreferrer">
              link
            </a>
          </p>
        ),
      },
    ],
  },
  [TYPE.THREADS]: {
    steps: [
      {
        text: <p>Ensure Your Threads Account Is Linked to Instagram</p>,
        subSteps: [
          <p>
            Click on the <strong>menu</strong> (in the top-right) corner and go
            to <strong>Account settings</strong>
          </p>,

          <p>
            Check if your{' '}
            <strong>Threads account is linked to your Instagram</strong> account
          </p>,
        ],
      },
      {
        text: (
          <p>
            For <strong>step-by-step guide</strong>, click this{' '}
            <a href={guideLinks[TYPE.THREADS]} target="_blank" rel="noreferrer">
              link
            </a>
          </p>
        ),
      },
    ],
  },
  [TYPE.GOOGLE_MY_BUSINESS]: {
    steps: [
      {
        text: <p>Check Your Google Account Permissions</p>,
        subSteps: [
          <p>
            Go to <strong>Google Account Settings</strong>
          </p>,

          <p>
            Navigate to <strong>Security → Third-party apps</strong> with
            account access.
          </p>,
          <p>
            Look for the Kontentino under <strong>Third-party apps</strong>
          </p>,
          <p>
            Ensure that the app has the <strong>necessary permissions</strong>{' '}
            (e.g., "Manage your account" or "Access your Google services")
          </p>,
        ],
      },
      {
        text: (
          <p>
            For <strong>step-by-step guide</strong>, click this{' '}
            <a
              href={guideLinks[TYPE.GOOGLE_MY_BUSINESS]}
              target="_blank"
              rel="noreferrer"
            >
              link
            </a>
          </p>
        ),
      },
    ],
  },
  [TYPE.TIKTOK]: {
    steps: [
      {
        text: (
          <p>
            Only <strong>business accounts</strong> can be connected to
            third-party platforms.
          </p>
        ),
        subSteps: [
          <p>
            Go to{' '}
            <strong>
              Profile {'>'} Menu {'>'} Settings and Privacy
            </strong>
          </p>,

          <p>
            Click on <strong>Manage Account</strong>
          </p>,
          <p>
            Check if your account type is <strong>Business</strong>. If it’s{' '}
            <strong>Personal</strong>, switch it to <strong>Business</strong>.
          </p>,
        ],
      },
      {
        text: (
          <p>
            For <strong>step-by-step guide</strong>, click this{' '}
            <a href={guideLinks[TYPE.TIKTOK]} target="_blank" rel="noreferrer">
              link
            </a>
          </p>
        ),
      },
    ],
  },
};
