import { createComposeRoute } from 'app/utils/routes';
import routeConfig from 'app/config/routes';

export const composeRoute = createComposeRoute(routeConfig);

const routes = {
  LOGIN: composeRoute('public.routes.login.path'),
  FORGOT_PASSWORD: composeRoute('public.routes.forgotPassword.path'),
  FORGOT_PASSWORD_SUBMIT_EMAIL: composeRoute(
    'public.routes.forgotPassword.routes.submitEmail.path',
  ),
  FORGOT_PASSWORD_SUBMIT_CODE: composeRoute(
    'public.routes.forgotPassword.routes.submitCode.path',
  ),
  FORGOT_PASSWORD_SET_PASSWORD: composeRoute(
    'public.routes.forgotPassword.routes.setPassword.path',
  ),
  PUBLIC_PDF_REPORTS: composeRoute('public.routes.pdfReports.path'),
  PUBLIC_PDF_REPORTS_INSIGHTS_POSTS_PERFORMANCE: composeRoute(
    'public.routes.pdfReports.routes.insightsPostsPerformance.path',
  ),
  PUBLIC_PDF_REPORTS_INSIGHTS_COMPETITION_ANALYSIS: composeRoute(
    'public.routes.pdfReports.routes.insightsCompetitionAnalysis.path',
  ),
  PUBLIC_PDF_REPORTS_INSIGHTS_PAGE_PERFORMANCE_CURRENT_SELECTION: composeRoute(
    'public.routes.pdfReports.routes.insightsPagePerformance.routes.currentSelection.path',
  ),
  PUBLIC_PDF_REPORTS_INSIGHTS_PAGE_PERFORMANCE_OVERVIEW: composeRoute(
    'public.routes.pdfReports.routes.insightsPagePerformance.routes.overview.path',
  ),
  RESET_PASSWORD: composeRoute('public.routes.resetPassword.path'),
  DASHBOARD: composeRoute('dashboard.path'),
  POSTS: composeRoute('posts.path'),
  POSTS_CALENDAR: composeRoute('posts.routes.calendar.path'),
  POSTS_KANBAN: composeRoute('posts.routes.kanban.path'),
  POSTS_GRID: composeRoute('posts.routes.grid.path'),
  POSTS_LIST: composeRoute('posts.routes.list.path'),
  REPORTS: composeRoute('public.routes.reports.path'),
  INSIGHTS: composeRoute('insights.path'),
  INSIGHTS_POSTS_PERFORMANCE: composeRoute(
    'insights.routes.postsPerformance.path',
  ),
  INSIGHTS_PAGE_PERFORMANCE: composeRoute(
    'insights.routes.pagePerformance.path',
  ),
  INSIGHTS_COMPETITION_ANALYSIS: composeRoute(
    'insights.routes.competitionAnalysis.path',
  ),
  INSIGHTS_LITE: composeRoute('insightsLite'),
  IN_APP_NOTIFICATIONS: composeRoute('notifications.routes.history.path'),
  ADS_PLANNER: composeRoute('adsPlanner.path'),
  ADS_PLANNER_LIST: composeRoute('adsPlanner.routes.list.path'),
  ADS_PLANNER_TIMELINE: composeRoute('adsPlanner.routes.timeline.path'),
  AI_CONTENT: composeRoute('aiContent.path'),
  BRAND_HUB: composeRoute('brandHub.path'),
  ONBOARDING_SETUP_ACCOUNT: composeRoute(
    'onboarding.routes.initSetupAccount.path',
  ),
};

export const modalRoutes = {
  POST_DETAIL: 'post',
  POST_REVISION_DETAIL: 'post-revision',
  INSPIRATION_DETAIL: 'inspiration',
  PUBLIC_INVITE_USER: 'public-invite-user',
  DELETE_POST: 'delete-post',
  NEW_PROFILE: 'new-profile',
  ASSOCIATE_POSTS_PROFILE: 'associate-posts-profile',
  NEW_CONTENT_PLAN: 'new-content-plan',
  REFERRAL_PROGRAM: 'referral-program',
  MANAGE_PROFILE_GROUP: 'manage-profile-group',
  SHARE_PREVIEW: 'share-preview',
  WELCOME: 'welcome',
  SELECT_SINGLE_PROFILE_BEFORE_COMPOSE: 'select-single-profile-before-compose',
  // Posts - Time savers
  ASSIGN_MULTIPLE_TASKS: 'assign-multiple-tasks',
  CHANGE_POSTS_STATUS: 'change-posts-status',
  RESPOND_TO_APPROVAL_REQUEST: 'respond-to-approval-request',
  DUPLICATE_POSTS: 'duplicate-posts',
  MOVE_POSTS: 'move-posts',
  SCHEDULE_POSTS: 'schedule-posts',
  SEND_FOR_CLIENT_APPROVAL: 'send-for-client-approval',
  SEND_FOR_INTERNAL_APPROVAL: 'send-for-internal-approval',
  // Ads planner
  ADD_AD_ACCOUNT: 'add-ad-account',
  CAMPAIGN: {
    EDIT: 'edit-campaign',
  },
  AD_SET: {
    DUPLICATE: 'duplicate-ad-set',
    EDIT: 'edit-ad-set',
  },
  CREATE_CAMPAIGN: 'create-campaign',
  CREATE_AD_SET: 'create-ad-set',
  AD: 'ad',
  IMPORT_REMOTE_FACEBOOK_CAMPAIGNS: 'import-facebook-campaigns',
  DUPLICATE_ADS: 'duplicate-ads',
  MOVE_ADS: 'move-ads',
  TRIAL: {
    START: 'trial-start',
    EXPIRED: 'trial-expired',
  },
  SUBSCRIPTION: 'subscription-expired',
  PROFILE_LIMIT_REACHED: 'profile-limit-reached',
  BRAND_HUB: {
    MANAGE_PROFILE: 'manage-profile',
    CREATE_CREATIVE: 'create-creative',
  },
  INVITE_USER: 'invite-user',
};

export const routeNames = {
  [routes.LOGIN]: 'Login | Kontentino',
  [routes.RESET_PASSWORD]: 'Reset password | Kontentino',
  [routes.DASHBOARD]: 'Dashboard',
  [routes.POSTS]: 'Posts',
  [routes.IN_APP_NOTIFICATIONS]: 'Notifications',
  [routes.REPORTS]: 'Reports',
  [routes.INSIGHTS]: 'Analytics',
  [routes.INSIGHTS_POSTS_PERFORMANCE]: 'Analytics | Posts Performance',
  [routes.INSIGHTS_PAGE_PERFORMANCE]: 'Analytics | Page Performance',
  [routes.INSIGHTS_COMPETITION_ANALYSIS]: 'Analytics | Competition Analysis',
  [routes.ADS_PLANNER]: 'Ads planner',
};

export default routes;
